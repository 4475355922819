import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AudioType } from '@app/preferences/models/settings.models';

interface FileUploadData {
  Name: string;
  Id;
}

@Component({
  selector: 'app-upload-music-dialog',
  templateUrl: './upload-music-dialog.component.html',
  styleUrls: ['./upload-music-dialog.component.scss'],
})
export class UploadMusicDialogComponent implements OnInit {
  @Output() completeEvent = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  private fileId: string = '';
  protected fileName: string = '';
  protected songDescription: string = '';
  protected files: File[] = [];
  protected loadingFiles: boolean = false;

  constructor(
    public dialogReference: DialogRef<boolean>,
    private appConfigService: AppConfigService,

    @Inject(DIALOG_DATA) public currentFile: FileUploadData
  ) {}

  ngOnInit(): void {
    //Only show the custom status in the status field, do not show the presence text
    this.fileId = this.currentFile.Id || '0';
  }

  openFileSelector() {
    this.fileInput.nativeElement.click();
  }

  protected addFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      this.files = Array.from(target.files);
      this.fileName = this.files.map((file) => file.name).join('; ');
    }
    target.value = '';
  }

  async onSubmit() {
    this.loadingFiles = true;
    const fileType = this.files[0].type === 'audio/mpeg' ? 'audio/mp3' : this.files[0].type;
    const data = { description: this.songDescription, name: this.songDescription, format: fileType };
    await this.appConfigService.uploadAudioFile(this.fileId, data, this.files[0], AudioType.moh);

    //Set a 3 second timeout so the GET will return the new uploaded file
    const uploadCompleted = await this.pollForUploadCompletion(15, 500);
    if (uploadCompleted) {
      this.loadingFiles = false;
      this.completeEvent.emit(true);
      this.dialogReference.close();
    }
  }

  close(): void {
    this.dialogReference.close();
  }

  /**
   * Async function which polls api endpoint to detect when upload has completed. Returns a promise which resolves to true if upload has completed, false otherwise.
   * @param attempts number of attempts to try
   * @param interval interval between each attempt (milliseconds)
   * @returns
   */
  async pollForUploadCompletion(attempts: number, interval: number): Promise<boolean> {
    let count = 0;
    while (count < attempts) {
      const audioFiles = await this.appConfigService.getAudioFiles();
      // Check if the uploaded file was returned
      const result = audioFiles.find((c) => c.index === Number(this.fileId));
      if (result) {
        return true;
      }
      count++;
      await new Promise((resolve) => setTimeout(resolve, interval));
    }
    return false;
  }
}
