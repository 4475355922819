<div class="favicon-container">
  @if (integration().favicon) {
    <img
      class="favicon-img"
      [src]="integration().favicon"
      alt="" />
  } @else {
    <span class="favicon-initials">{{ integration().display_name | initials }}</span>
  }
</div>
