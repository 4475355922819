<section #container>
  <div class="container">
    <h2 class="m-b-10">
      <b>{{ 'MUSIC_ON_HOLD_PREFERENCES.TITLE' | translate }}</b>
    </h2>
    <mat-divider class="m-b-16"></mat-divider>

    <div class="flex-col">
      <div
        class="item flex moh-switch"
        [matTooltip]="'MUSIC_ON_HOLD_PREFERENCES.ENABLE_TOOLTIP' | translate"
        [matTooltipPosition]="'right'">
        <mat-slide-toggle
          [checked]="mohSettings.enableMoh"
          (change)="toggleMusicOnHoldSettings()"></mat-slide-toggle>
        <label class="text moh-switch-label">{{ 'MUSIC_ON_HOLD_PREFERENCES.ENABLE_LABEL' | translate }}</label>
      </div>
      <div
        class="item flex moh-switch"
        [matTooltip]="'MUSIC_ON_HOLD_PREFERENCES.RANDOMIZE_TOOLTIP' | translate"
        [matTooltipPosition]="'right'">
        <mat-slide-toggle
          [checked]="mohSettings.randomizeMoh"
          (change)="toggleRandomizeMusicOnHold()"></mat-slide-toggle>
        <label class="text">{{ 'MUSIC_ON_HOLD_PREFERENCES.RANDOMIZE_LABEL' | translate }}</label>
      </div>
    </div>

    <label class="m-t-20 m-b-10 sub-header"
      ><b>{{ 'MUSIC_ON_HOLD_PREFERENCES.MANAGE_TITLE' | translate }}</b></label
    >
    <mat-divider class="m-b-16"></mat-divider>
    <div
      class="flex-col"
      cdkDropListGroup
      [cdkDropListGroupDisabled]="editingDisabled">
      @if (this.mohFiles.length === 0) {
        <p class="empty-file-list">
          {{ 'MUSIC_ON_HOLD_PREFERENCES.NO_FILES_MESSAGE' | translate }}
        </p>
      } @else if (this.mohFiles.length > 0) {
        <table class="moh-file-list">
          <tr>
            <th colspan="2"></th>
            <th>{{ 'MUSIC_ON_HOLD_PREFERENCES.FILE_NAME' | translate }}</th>
            <th>{{ 'MUSIC_ON_HOLD_PREFERENCES.DURATION' | translate }}</th>
            <th>{{ 'MUSIC_ON_HOLD_PREFERENCES.FILE_SIZE' | translate }}</th>
            <th></th>
          </tr>
          <tbody
            cdkDropList
            [cdkDropListData]="mohFiles"
            (cdkDropListDropped)="drop($event)">
            @for (item of mohFiles; track item.index) {
              <tr cdkDrag>
                <td class="rearrange-files-btn">
                  <mat-icon>drag_indicator</mat-icon>
                </td>
                <td class="play-file-btn">
                  @if (playingIndex !== item.index) {
                    <mat-icon
                      color="primary"
                      (click)="playSelectedAudioFile(item.mediaUrl, item.index)"
                      >play_circle</mat-icon
                    >
                  } @else if (playingIndex === item.index) {
                    <mat-icon
                      color="primary"
                      (click)="pauseSelectedAudioFile()"
                      >stop_circle</mat-icon
                    >
                  }
                </td>
                <td>
                  @if (!item.edit) {
                    <span>{{ item.description }}</span>
                  } @else {
                    <span class="inline-file-action-btns">
                      <input [(ngModel)]="item.descriptionEdit" />
                      <mat-icon
                        color="primary"
                        (click)="saveDescriptionChange(item)"
                        title="{{ 'MUSIC_ON_HOLD_PREFERENCES.SAVE' | translate }}"
                        >save</mat-icon
                      >
                      <mat-icon
                        color="primary"
                        (click)="cancelDescriptionChange(item)"
                        title="{{ 'MUSIC_ON_HOLD_PREFERENCES.CANCEL' | translate }}"
                        >cancel</mat-icon
                      >
                    </span>
                  }
                </td>
                <td>{{ Number(item.duration) | secondsToMinutes }}</td>
                <td>{{ item.size }}</td>
                <td class="file-action-btns">
                  <mat-icon
                    color="primary"
                    (click)="downloadAudioFile(item.index)"
                    >download</mat-icon
                  >
                  <mat-icon
                    color="primary"
                    (click)="editDescription(item)"
                    >edit</mat-icon
                  >
                  <mat-icon
                    color="primary"
                    (click)="deleteAudioFile(item.index)"
                    >delete</mat-icon
                  >
                </td>
              </tr>
            }
          </tbody>
        </table>
      }

      <button
        color="primary"
        mat-raised-button
        class="upload-btn"
        type="button"
        (click)="openFileUploadDialog()">
        <span>{{ 'MUSIC_ON_HOLD_PREFERENCES.UPLOAD_BTN' | translate }}</span>
      </button>
    </div>

    <h2 class="m-t-30 m-b-10">
      <b>{{ 'MUSIC_ON_HOLD_PREFERENCES.GREETING_TITLE' | translate }}</b>
    </h2>
    <mat-divider class="m-b-16"></mat-divider>
    <div
      class="item flex moh-switch"
      [matTooltip]="'MUSIC_ON_HOLD_PREFERENCES.PLAY_GREETING_TOOLTIP' | translate"
      [matTooltipPosition]="'right'">
      <mat-slide-toggle
        [checked]="enablePlayIntroductoryGreeting"
        (change)="togglePlayIntroGreeting()"></mat-slide-toggle>
      <label class="text">{{ 'MUSIC_ON_HOLD_PREFERENCES.PLAY_GREETING_LABEL' | translate }}</label>
    </div>

    @if (enablePlayIntroductoryGreeting) {
      <div class="intro-greeting-options m-t-25 m-b-10">
        <label class="sub-header"
          ><b>{{ 'MUSIC_ON_HOLD_PREFERENCES.NEW_GREETING' | translate }}</b></label
        >
        <mat-radio-group
          class="greeting-radio-btns m-b-10"
          color="primary"
          [(ngModel)]="mohSettings.greetingType"
          (change)="onGreetingChange()">
          <mat-radio-button value="TTS">{{
            'MUSIC_ON_HOLD_PREFERENCES.GREETING_TYPE_TTS' | translate
          }}</mat-radio-button>
          <mat-radio-button value="Upload">{{
            'MUSIC_ON_HOLD_PREFERENCES.GREETING_TYPE_UPLOAD' | translate
          }}</mat-radio-button>
          <mat-radio-button value="Recording">{{
            'MUSIC_ON_HOLD_PREFERENCES.GREETING_TYPE_RECORDING' | translate
          }}</mat-radio-button>
        </mat-radio-group>
        @if (mohSettings.greetingType === 'TTS') {
          <div class="text-to-speech-options">
            <label class="sub-header"
              ><b>{{ 'MUSIC_ON_HOLD_PREFERENCES.MESSAGE_LABEL' | translate }}</b></label
            >

            <mat-form-field
              appearance="outline"
              class="density-minus-3">
              <textarea
                #greetingTextArea
                matInput
                [(ngModel)]="mohSettings.greetingMessage"
                cdkAutosizeMinRows="3"
                maxlength="200"
                (change)="disabled = false; greetingChanged = true"></textarea>
            </mat-form-field>

            <label class="sub-header"
              ><b>{{ 'MUSIC_ON_HOLD_PREFERENCES.LANGUAGE_LABEL' | translate }}</b></label
            >
            <mat-form-field
              appearance="outline"
              class="file-dropdown density-minus-3">
              <mat-select
                class="moh-file-select"
                placeholder="{{ 'MUSIC_ON_HOLD_PREFERENCES.LANGUAGE_LABEL' | translate }}"
                (selectionChange)="onLanguageChanged($event.value)"
                [(ngModel)]="mohSettings.greetingLanguage">
                @for (item of languages; track item.id) {
                  <mat-option [value]="item.id">{{ item.name }} ({{ item.country }})</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <label class="sub-header"
              ><b>{{ 'MUSIC_ON_HOLD_PREFERENCES.VOICE_LABEL' | translate }}</b></label
            >
            <mat-form-field
              appearance="outline"
              class="file-dropdown density-minus-3">
              <mat-select
                class="moh-file-select"
                placeholder="{{ 'MUSIC_ON_HOLD_PREFERENCES.VOICE_LABEL' | translate }}"
                (selectionChange)="onVoiceChanged($event.value)"
                [(ngModel)]="mohSettings.greetingVoice">
                @for (item of voiceOptions; track item.id) {
                  <mat-option [value]="item.id">{{ item.name }} ({{ item.type }})</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        } @else if (mohSettings.greetingType === 'Upload') {
          <div class="text-to-speech-options">
            @if (showUploadAudioPlayer) {
              <app-audio-player
                [audio]="track"
                class="player"
                (delete)="deleteAudioFile(mohSettings.greetingIndex)"
                (download)="downloadAudioFile(mohSettings.greetingIndex)"></app-audio-player>
            }
            @if (!showUploadOptions) {
              <button
                (click)="showUploadOptions = true"
                mat-raised-button
                class="m-l-12 save-button"
                type="button"
                color="primary">
                {{ 'MUSIC_ON_HOLD_PREFERENCES.UPLOAD_FILE_BUTTON' | translate }}
              </button>
            } @else {
              <div>
                <mat-form-field
                  appearance="outline"
                  class="file-upload form-no-error density-minus-3">
                  <mat-label>{{ 'MUSIC_ON_HOLD_PREFERENCES.FILE_NAME_LABEL' | translate }}</mat-label>
                  <input
                    [(ngModel)]="uploadGreetingFileName"
                    autocomplete="off"
                    class="search-call-history"
                    matInput
                    disabled />
                </mat-form-field>
                <div class="download-btn">
                  <button
                    class="btn"
                    color="primary"
                    (click)="selectGreetingFile()">
                    {{ 'MUSIC_ON_HOLD_PREFERENCES.SELECT_DIFFERENT_FILE' | translate }}
                    <mat-icon color="primary">upload</mat-icon>
                  </button>
                  <input
                    type="file"
                    #fileInput
                    accept="audio"
                    (change)="addFile($event)"
                    [style.display]="'none'" />
                </div>
              </div>
            }
          </div>
          @if (microphoneAccessDenied) {
            <div class="warning-support-text">
              {{ 'MUSIC_ON_HOLD_PREFERENCES.RECORDING_CANCELED' | translate }}
            </div>
          }
        } @else if (mohSettings.greetingType === 'Recording') {
          @if (recordedFile === null) {
            <div class="recorder relative">
              <div class="flex align-items-center">
                <div class="record-icon-container justify-content-center align-items-center">
                  @if (!isRecording) {
                    <div
                      class="record-icon cursor-pointer"
                      (click)="startInputSound()"></div>
                  } @else {
                    <div
                      class="recording-icon cursor-pointer"
                      (click)="stopInputSound()"></div>
                  }
                </div>
                <div class="track-duration">{{ Number(recordingSeconds) | secondsToMinutes }}</div>
                <div class="recorder-slider-container">
                  <mat-slider
                    disabled
                    min="0"
                    max="10"
                    step="1"
                    ngDefaultControl
                    class="recorder-slider w-100"
                    #ngSlider
                    ><input
                      matSliderThumb
                      [(ngModel)]="recordingSeconds"
                      #ngSliderThumb="matSliderThumb"
                  /></mat-slider>
                </div>
              </div>
            </div>
          }
          @if (recordedFile !== null && track && track.duration) {
            <div class="recorder relative">
              <div class="flex align-items-center">
                <div class="justify-content-center align-items-center">
                  <button
                    class="play-pause"
                    color="primary"
                    (click)="onRecordedFilePlayClicked()"
                    mat-mini-fab>
                    <mat-icon
                      aria-hidden="true"
                      class="play-track"
                      >{{ playingAudioId === 'recorded' ? 'pause' : 'play_arrow' }}
                    </mat-icon>
                  </button>
                </div>
                <div class="track-duration">{{ Number(recordingSeconds) | secondsToMinutes }}</div>
                <div class="recorder-slider-container">
                  <mat-slider
                    min="0"
                    [max]="track.duration"
                    step="1"
                    ngDefaultControl
                    class="recorder-slider w-100"
                    #ngSlider
                    ><input
                      matSliderThumb
                      [(ngModel)]="recordingSeconds"
                      #ngSliderThumb="matSliderThumb"
                  /></mat-slider>
                </div>
                <div class="recorder-delete-container">
                  <button
                    (click)="deleteRecording(mohSettings.greetingIndex)"
                    color="primary"
                    mat-icon-button>
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          }
        }
      </div>
    }
  </div>
  @if (editingDisabled) {
    <div
      class="grey-mask"
      #greyMask></div>
  }
</section>
