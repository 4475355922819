import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppTranslateService } from '@app/core/services/app-translate.service';
import { VoicemailSettingPageType } from '@app/preferences/models/voicemail.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-settings-voicemail-change-pin',
  templateUrl: './voicemail-change-pin.component.html',
  styleUrls: ['../../voicemail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatInputModule,
    TranslateModule,
  ],
})
export class VoicemailChangePinComponent {
  @Output() selectedPageChanged = new EventEmitter<VoicemailSettingPageType>();
  @Output() submitForm = new EventEmitter<string>();

  protected pinForm: FormGroup;
  protected processing = false;
  protected isPinHide = true;
  private minPinLength = 4;
  private maxPinLength = 10;

  constructor(
    private formBuilder: FormBuilder,
    private appTranslateService: AppTranslateService
  ) {
    this.pinForm = formBuilder.group(
      {
        pin: new FormControl('', [
          Validators.required,
          Validators.minLength(this.minPinLength),
          Validators.maxLength(this.maxPinLength),
        ]),
        confirmPin: new FormControl('', [
          Validators.required,
          Validators.minLength(this.minPinLength),
          Validators.maxLength(this.maxPinLength),
        ]),
      },
      {
        validator: this.ConfirmedValidator('pin', 'confirmPin'),
      }
    );
  }

  protected getPinValidationMessage(): string {
    return this.appTranslateService.instant('VOICE_MAIL_PREFERENCES.PIN_VALIDATION', {
      min: this.minPinLength,
      max: this.maxPinLength,
    });
  }

  setSelectedPage(page: VoicemailSettingPageType) {
    this.selectedPageChanged.emit(page);
  }

  toggleIsPinHide() {
    this.isPinHide = !this.isPinHide;
  }

  ConfirmedValidator(controlName: string, matchingControlName: string): object {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value === matchingControl.value) {
        matchingControl.setErrors(null);
      } else {
        matchingControl.setErrors({ confirmedValidator: true });
      }
    };
  }

  save() {
    this.processing = true;
    if (this.pinForm.valid) {
      this.submitForm.emit(this.pinForm.controls['pin'].value);
    }
  }
}
