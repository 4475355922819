import { Component, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterTelHandlerComponent } from '@app/core/components/banners/register-tel-handler/register-tel-handler.component';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { ProtocolHandlerService } from '@app/core/services/protocol-handler.service';
import { PwaService } from '@app/core/services/pwa.service';
import { ElectronService } from '@app/electron/electron.service';
import { Integration } from '@app/preferences/models/integrations.models';
import { LambdaLogDBModel } from '@app/preferences/models/lambda.model';
import { IntegrationsService } from '@app/preferences/services/integrations.service';
import { LambdaService } from '@app/preferences/services/lambda.service';
import { RegisterTelHandlerDialogComponent } from '@app/shared/components/register-tel-handler-dialog/register-tel-handler-dialog.component';
import { downloadFile } from '@app/shared/utils/file.util';
import { environment } from '@environment/environment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent {
  protected readonly panelOpenState = signal(false);

  protected readonly isRunningInElectron = this.electronService.isRunningInElectron;
  readonly AppStoreUrls = {
    macAppStore: environment.app.macOS.appStore,
    microsoftStore: environment.app.windows.microsoftStore,
  };
  protected readonly AppFeature = AppFeature;

  constructor(
    protected pwaService: PwaService,
    protected appConfigService: AppConfigService,
    protected protocolHandlerService: ProtocolHandlerService,
    protected electronService: ElectronService,
    protected integrationsService: IntegrationsService,
    protected lambdaService: LambdaService,
    private dialog: MatDialog
  ) {}

  async installConnectUCPwa() {
    if (await this.pwaService.promptInstall()) {
      this.dialog.open(RegisterTelHandlerDialogComponent, {
        data: {
          protocolUrl: RegisterTelHandlerComponent.protocolUrl,
        },
        disableClose: true,
      });
    }
  }

  protected registerTelHandler() {
    try {
      this.protocolHandlerService.registerProtocolHandler('tel', RegisterTelHandlerComponent.protocolUrl);
    } catch (error) {
      console.error('registerProtocolHandler error', error);
    }
  }

  protected handleAppStoreUrlClick(url: string) {
    if (url) {
      window.open(url);
    }
  }

  protected async handleIntegrationUpdated(integration: Integration) {
    try {
      await this.integrationsService.putIntegration(integration);
    } catch (error) {
      console.error('handleIntegrationUpdated error', error);
    }
  }

  protected handleDownloadLogClick(log: LambdaLogDBModel) {
    downloadFile(`lambda-log-${log.createdAt}.txt`, JSON.stringify(log, null, 2), 'document');
  }
}
