<div
  class="add-time-frame-container"
  [ngClass]="{ standalone: standalone }">
  <ng-container *ngIf="standalone">
    <div class="header">
      <h1>
        <mat-icon (click)="onCancelEvent()">chevron_left</mat-icon>
        {{ 'ANSWERING_RULES_PREFERENCES.MANAGE_TIME_FRAME' | translate }}
      </h1>
    </div>
  </ng-container>

  <form [formGroup]="form">
    <mat-form-field
      class="input-40 time-frame"
      hideRequiredMarker
      appearance="outline"
      floatLabel="always">
      <mat-label>{{ 'ANSWERING_RULES_PREFERENCES.NAME_NEW_TIME_FRAME' | translate }}</mat-label>
      <mat-hint class="small-text">{{ 'ANSWERING_RULES_PREFERENCES.NAME_CANNOT_BE_CHANGED' | translate }}</mat-hint>
      <input
        matInput
        type="text"
        autocomplete="off"
        formControlName="name"
        value="My Time Frame"
        [readonly]="timeFrame !== undefined" />
    </mat-form-field>

    <div class="subheader">{{ 'ANSWERING_RULES_PREFERENCES.WHEN' | translate }}:</div>
    <mat-radio-group
      class="options"
      color="primary"
      formControlName="selectedOption"
      (change)="onSelectionChange()">
      <mat-radio-button
        #alwaysButton
        [disableRipple]="true"
        [value]="TimeFrameType.Always">
        {{ 'ANSWERING_RULES_PREFERENCES.ALWAYS' | translate }}
      </mat-radio-button>
      <mat-radio-button
        #daysOfWeekButton
        [disableRipple]="true"
        value="daysAndTimes">
        {{ 'ANSWERING_RULES_PREFERENCES.DAYS_AND_TIMES_WEEK' | translate }}
      </mat-radio-button>
      <mat-radio-button
        #specificDatesButton
        [disableRipple]="true"
        value="specificDates">
        {{ 'ANSWERING_RULES_PREFERENCES.SPECIFIC_DATES_RANGES' | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <ng-container *ngIf="selectedOption.value === TimeFrameType.DaysAndTimes">
      <!-- <div class="subheader">Days of the week and times:</div> -->
      <div
        class="days-and-times"
        formArrayName="daysAndTimes">
        <div
          class="day-of-week"
          *ngFor="let ctrl of daysAndTimes.controls; index as i"
          [formGroupName]="i">
          <mat-checkbox
            formControlName="checked"
            [color]="'primary'"
            [disableRipple]="true"
            (change)="onDayOfWeekChange($event, i)">
            {{ daysOfWeek[i] }}
          </mat-checkbox>
          <div
            *ngIf="ctrl.get('checked')?.value"
            formArrayName="ranges">
            <div
              *ngFor="let dayCtrl of daysAndTimesRanges(i).controls; index as j"
              class="time-range"
              [formGroupName]="j">
              <div class="time-range-fields">
                <mat-form-field
                  class="time-field input-40"
                  appearance="outline">
                  <input
                    matInput
                    formControlName="timeFrom"
                    type="time" />
                </mat-form-field>
                <mat-icon class="arrow">arrow_right_alt</mat-icon>
                <mat-form-field
                  class="time-field input-40"
                  appearance="outline">
                  <input
                    matInput
                    formControlName="timeTo"
                    type="time" />
                </mat-form-field>
              </div>
              <div class="buttons">
                <mat-icon (click)="removeDaysAndTimesRange(i, j)">delete_outline</mat-icon>
                <mat-icon
                  [ngClass]="{ disabled: !dayCtrl.valid }"
                  [matMenuTriggerFor]="copyTimes"
                  (click)="copyTimesOpen(i, j)">
                  content_copy
                </mat-icon>
                <mat-icon
                  [ngClass]="{ disabled: !dayCtrl.valid }"
                  (click)="addDaysAndTimesRange(i)"
                  >add_box</mat-icon
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="form.get('selectedOption')?.value === TimeFrameType.SpecificDates">
      <div class="subheader">{{ 'ANSWERING_RULES_PREFERENCES.SPECIFIC_DATES_RANGES' | translate }}:</div>
      <div class="ranges">
        <ng-container formArrayName="specificDates">
          <div
            class="range"
            *ngFor="let ctrl of specificDates.controls; index as i"
            [formGroupName]="i">
            <mat-form-field
              class="date input-40"
              appearance="outline"
              floatLabel="always"
              hideRequiredMarker="true"
              (click)="fromPicker.open()">
              <mat-label>{{ 'ANSWERING_RULES_PREFERENCES.START_DATE' | translate }}</mat-label>
              <input
                matInput
                formControlName="fromDate"
                [matDatepicker]="fromPicker" />
              <mat-datepicker
                #fromPicker
                [disabled]="false"
                (closed)="fromDateSelected(i)">
              </mat-datepicker>
            </mat-form-field>
            <mat-form-field
              class="time-field input-40"
              appearance="outline"
              floatLabel="always"
              hideRequiredMarker="true">
              <mat-label>{{ 'ANSWERING_RULES_PREFERENCES.START_TIME' | translate }}</mat-label>
              <input
                matInput
                formControlName="fromTime"
                type="time" />
            </mat-form-field>
            <mat-icon class="arrow">arrow_right_alt</mat-icon>
            <mat-form-field
              class="date input-40"
              appearance="outline"
              floatLabel="always"
              hideRequiredMarker="true"
              (click)="toPicker.open()">
              <mat-label>{{ 'ANSWERING_RULES_PREFERENCES.END_DATE' | translate }}</mat-label>
              <input
                matInput
                formControlName="toDate"
                [min]="ctrl.get('fromDate')?.value"
                [matDatepicker]="toPicker" />
              <mat-datepicker
                #toPicker
                [disabled]="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              class="time-field input-40"
              appearance="outline"
              floatLabel="always"
              hideRequiredMarker="true">
              <mat-label>{{ 'ANSWERING_RULES_PREFERENCES.END_TIME' | translate }}</mat-label>
              <input
                matInput
                formControlName="toTime"
                type="time"
                [matTooltip]="ctrl.get('toTime')?.errors?.maxTimeError || ctrl.get('toTime')?.errors?.toTimeError" />
            </mat-form-field>
            <mat-icon
              *ngIf="i === 0"
              class="add-icon"
              [ngClass]="{ disabled: specificDates.invalid }"
              (click)="addSpecificDatesRange()">
              add_box
            </mat-icon>
            <mat-icon
              *ngIf="i !== 0"
              class="add-icon"
              (click)="removeSpecificDatesRange(i)">
              delete_outline
            </mat-icon>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </form>
  <ng-container *ngIf="standalone">
    <div
      *ngIf="timeFrame"
      class="delete-time-frame"
      [ngClass]="{ disabled: timeFrame.in_use }"
      [matTooltip]="
        timeFrame.in_use ? 'This time frame is used by you or assigned to multiple users and cannot be removed' : ''
      "
      matTooltipShowDelay="500"
      (click)="!timeFrame.in_use && confirmDeleteTimeFrame()">
      <div class="text">{{ 'ANSWERING_RULES_PREFERENCES.DELETE_TIME_FRAME' | translate }}</div>
      <mat-icon>delete_outline</mat-icon>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="standalone">
  <div class="main-buttons">
    <button
      mat-button
      type="button"
      color="primary"
      (click)="onCancelEvent()">
      {{ 'ANSWERING_RULES_PREFERENCES.CANCEL' | translate }}
    </button>
    <button
      mat-raised-button
      type="button"
      color="primary"
      [disabled]="form.invalid || form.pristine || saving"
      (click)="onSaveEvent()">
      <mat-spinner
        diameter="24"
        *ngIf="saving"></mat-spinner>
      <span *ngIf="!saving">{{ 'ANSWERING_RULES_PREFERENCES.SAVE' | translate }}</span>
    </button>
  </div>
</ng-container>

<mat-menu
  #copyTimes="matMenu"
  (closed)="copyMenuClosed($event)">
  <div class="copy-header">{{ 'ANSWERING_RULES_PREFERENCES.COPY_TIMES' | translate }}</div>
  <ng-container *ngFor="let item of daysOfWeek; index as i">
    <div
      class="menu-item"
      (click)="$event.stopPropagation()">
      <div
        class="menu-item-text"
        [ngClass]="{ disabled: i === copyTimesInitiator }">
        {{ item }}
      </div>
      <mat-checkbox
        [color]="'primary'"
        [disabled]="i === copyTimesInitiator"
        [(ngModel)]="copyTimesModel[i]"
        [disableRipple]="true"></mat-checkbox>
    </div>
  </ng-container>
  <mat-divider></mat-divider>
  <button
    mat-menu-item
    (click)="copyTimesClick()">
    {{ 'ANSWERING_RULES_PREFERENCES.COPY_TIMES_BUTTON' | translate }}
  </button>
</mat-menu>
