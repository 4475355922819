<div
  class="audio-settings-container"
  [ngClass]="{ 'audio-settings-container-overflow': displayedInPopupWindow }">
  <div class="audio-header">
    <h1 class="header-title">{{ 'AUDIO.AUDIO_SETTINGS' | translate }}</h1>
    <button
      class="rescan-button"
      (click)="scanDevices()">
      {{ 'AUDIO.RESCAN_DEVICES' | translate }}
    </button>
  </div>
  <form
    [formGroup]="audioForm"
    class="text-left">
    <div class="title-audio-setting">{{ 'AUDIO.NOTIFICATIONS_AND_RINGING' | translate }}</div>
    <div class="volume-settings">
      <div class="volume-setting-div">
        <mat-label class="label-style">{{ 'AUDIO.NOTIFICATION_SOUND_VOLUME' | translate }}</mat-label>
        <mat-slider
          data-cy="NotificationSoundVolume"
          class="volume-slider"
          [max]="100"
          [min]="0"
          (change)="onChange('Notification')">
          <input
            matSliderThumb
            formControlName="notificationSoundVolume" />
        </mat-slider>
      </div>
      <div class="volume-setting-div add-padding">
        <mat-label class="label-style">{{ 'AUDIO.RINGING_SOUND_VOLUME' | translate }}</mat-label>
        <mat-slider
          data-cy="RingingSoundVolume"
          class="volume-slider"
          [max]="100"
          [min]="0"
          (change)="onChange('Call')">
          <input
            matSliderThumb
            formControlName="ringingSoundVolume" />
        </mat-slider>
      </div>
      <mat-form-field
        data-cy="NotificationSpeakerDevice"
        appearance="outline"
        class="speaker-dropdown density-minus-3">
        <mat-label>{{ 'AUDIO.RINGING_&_NOTIFICATION_SPEAKER' | translate }}</mat-label>
        <mat-select
          *ngIf="!displayedInPopupWindow"
          formControlName="notificationSpeakerDevice"
          (selectionChange)="disabled = false"
          class="device-list density-minus-3">
          <mat-option
            data-cy="NotificationSpeakerDeviceOption"
            *ngFor="let device of settingsService.outputDevices$ | async"
            [value]="device.deviceId"
            >{{ device.label }}</mat-option
          >
        </mat-select>
        <select
          (change)="disabled = false"
          *ngIf="displayedInPopupWindow"
          formControlName="notificationSpeakerDevice"
          matNativeControl
          required>
          <option
            *ngFor="let device of settingsService.outputDevices$ | async"
            [ngValue]="device.deviceId">
            {{ device.label }}
          </option>
        </select>
      </mat-form-field>
      <ng-template
        [ngTemplateOutlet]="testSound"
        [ngTemplateOutletContext]="{ $implicit: notificationSpeakerDevice, type: 'Notification' }"></ng-template>
      <!--      TODO: remove *ngIf="false" when we return to the quietRinging option-->
      <mat-checkbox
        *ngIf="false"
        formControlName="quietRinging"
        color="primary"
        >{{ 'AUDIO.QUIET_RINGING_WHEN_IN_MEETING' | translate }}
      </mat-checkbox>
      <div class="ring-calls">
        <mat-label
          class="label-style"
          matTooltip="Rings both the Ringing Speaker and the Calls and Meetings Speaker"
          >{{ 'AUDIO.ALSO_RING_CALLS_&_MEETINGS_SPEAKER' | translate }}</mat-label
        >
        <mat-slide-toggle
          formControlName="enableDualRinging"
          (change)="disabled = false"></mat-slide-toggle>
      </div>
    </div>
    <div class="title-audio-setting">{{ 'AUDIO.CALLS_&_MEETINGS' | translate }}</div>
    <div class="speaker-settings">
      <div class="output-settings">
        <mat-form-field
          appearance="outline"
          data-cy="SpeakerDevices"
          class="speaker-dropdown density-minus-3">
          <mat-label>{{ 'AUDIO.CALLS_AND_MEETINGS_SPEAKER' | translate }}</mat-label>
          <mat-select
            *ngIf="!displayedInPopupWindow"
            formControlName="speakerDevice"
            (selectionChange)="disabled = false"
            class="device-list density-minus-3">
            <mat-option
              data-cy="SpeakerDeviceOption"
              *ngFor="let device of settingsService.outputDevices$ | async"
              [value]="device.deviceId">
              {{ device.label }}
            </mat-option>
          </mat-select>
          <select
            (change)="disabled = false"
            *ngIf="displayedInPopupWindow"
            formControlName="speakerDevice"
            matNativeControl
            required>
            <option
              *ngFor="let device of settingsService.outputDevices$ | async"
              [ngValue]="device.deviceId">
              {{ device.label }}
            </option>
          </select>
        </mat-form-field>
        <ng-template
          [ngTemplateOutlet]="testSound"
          [ngTemplateOutletContext]="{ $implicit: speakerDevice, type: 'Call' }"></ng-template>
      </div>
      <div class="input-settings">
        <mat-form-field
          appearance="outline"
          data-cy="MicrophoneDevices"
          class="speaker-dropdown density-minus-3">
          <mat-label>{{ 'AUDIO.MICROPHONE' | translate }}</mat-label>
          <mat-select
            *ngIf="!displayedInPopupWindow"
            formControlName="microphoneDevice"
            (selectionChange)="disabled = false"
            [aria-label]="'Input'"
            class="device-list density-minus-3">
            <mat-option
              data-cy="MicrophoneDeviceOption"
              *ngFor="let device of settingsService.inputDevices$ | async"
              [value]="device.deviceId">
              {{ device.label }}
            </mat-option>
          </mat-select>
          <select
            (change)="disabled = false"
            *ngIf="displayedInPopupWindow"
            formControlName="microphoneDevice"
            matNativeControl
            required>
            <option
              *ngFor="let device of settingsService.inputDevices$ | async"
              [ngValue]="device.deviceId">
              {{ device.label }}
            </option>
          </select>
        </mat-form-field>
        <div class="result-div">
          <div>
            <button
              data-cy="MicrophoneTestButton"
              class="test-button"
              *ngIf="isRecording === false"
              (click)="testInputSound()">
              {{ 'AUDIO.TEST_SOUND' | translate }}
            </button>
            <button
              data-cy="MicrophoneStopTestButton"
              class="stop-test-button"
              *ngIf="isRecording === true"
              (click)="stopInputTest()">
              {{ 'AUDIO.STOP_TEST_AND_HEAR_RECORDING' | translate }}
            </button>
            <p
              *ngIf="counter > 0"
              class="counter-time">
              {{ (isRecording ? 'AUDIO.RECORDING_TIME' : 'AUDIO.PLAYING_TIME_LEFT') | translate }} : {{ counter }} sec
            </p>
          </div>
          <div class="sound-bar">
            <div class="meter">
              <span
                class="dot"
                [ngStyle]="{ 'background-color': i < currentMicrophoneVolume ? '#42a010' : '#E6E6E6' }"
                *ngFor="let item of [].constructor(10); let i = index"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template
  #testSound
  let-device
  let-type="type">
  <div class="result-div">
    <button
      data-cy="SpeakerTestButton"
      class="test-button"
      *ngIf="settingsService.isSetSinkIdSupported && activeTest !== type"
      (click)="testOutputDevice(device, type)">
      {{ 'AUDIO.TEST_SOUND' | translate }}
    </button>
    <button
      data-cy="SpeakerStopTestButton"
      class="stop-test-button"
      *ngIf="activeTest === type"
      (click)="stopOutputTest()">
      {{ 'AUDIO.STOP_TEST' | translate }}
    </button>
    <div class="sound-bar">
      <div class="meter">
        <span
          class="dot"
          [ngStyle]="{
            'background-color': i < currentSpeakerVolume && activeTest === type ? '#42a010' : '#E6E6E6'
          }"
          *ngFor="let item of [].constructor(10); let i = index"></span>
      </div>
    </div>
    <p
      *ngIf="!settingsService.isSetSinkIdSupported"
      class="warning-support-text">
      {{ 'AUDIO.BROWSER_NOT_SUPPORTED' | translate }}
      <!-- Your browser does not support this feature, please use Chrome, Edge or Opera to be able to use it. -->
    </p>
  </div>
</ng-template>
