import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Integration } from '@app/preferences/models/integrations.models';
import { IntegrationFaviconComponent } from '@app/shared/components/integration-favicon/integration-favicon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-integration-settings-item',
  standalone: true,
  imports: [CdkConnectedOverlay, CdkOverlayOrigin, IntegrationFaviconComponent, MatIcon, TranslateModule],
  templateUrl: './integration-settings-item.component.html',
  styleUrls: ['./integration-settings-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationSettingsItemComponent {
  @Input({ required: true }) integration: Integration;
  @Output() integrationChanged = new EventEmitter<Integration>();

  protected isOverlayOpen = false;

  protected integrationEnableChanged(event: Event) {
    this.integration.enabled = (event.target as HTMLInputElement).checked;
    this.integrationChanged.emit(this.integration);
  }

  protected integrationAutoPopChanged(event: Event) {
    this.integration.auto_pop = (event.target as HTMLInputElement).checked;
    this.integrationChanged.emit(this.integration);
  }

  protected integrationAutoDialChanged(event: Event) {
    this.integration.auto_dial = (event.target as HTMLInputElement).checked;
    this.integrationChanged.emit(this.integration);
  }
}
