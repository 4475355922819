<div class="password">
  <div class="scrollable-content">
    <h1 data-cy="change-password-title">
      <mat-icon (click)="close()">chevron_left</mat-icon>
      {{ 'CHANGE_PASSWORD.EDIT_PASSWORD' | translate }}
    </h1>
    <div class="requirements">
      <b>{{ 'CHANGE_PASSWORD.PASSWORD_REQUIREMENTS' | translate }}:</b>
      <div>
        <div>
          <div>
            <i [ngClass]="{ ok: !field['newPassword'].hasError('hasSmallCase') }"></i>
            <span>{{ 'CHANGE_PASSWORD.ONE_LOWER_CASE' | translate }}</span>
          </div>
          <div>
            <i [ngClass]="{ ok: !field['newPassword'].hasError('hasCapitalCase') }"></i>
            <span>{{ 'CHANGE_PASSWORD.ONE_UPPER_CASE' | translate }}</span>
          </div>
        </div>
        <div>
          <div>
            <i [ngClass]="{ ok: !field['newPassword'].hasError('hasNumber') }"></i>
            <span>{{ 'CHANGE_PASSWORD.ONE_NUMBER' | translate }}</span>
          </div>
          <div>
            <i [ngClass]="{ ok: field['newPassword'].value !== null && field['newPassword'].value.length >= 8 }"></i>
            <span>{{ 'CHANGE_PASSWORD.EIGHT_CHAR_MIN' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form">
      <form
        (ngSubmit)="changePassword()"
        [formGroup]="passwordForm">
        <mat-form-field
          appearance="outline"
          class="full density-minus-3">
          <mat-label>{{ 'CHANGE_PASSWORD.CURRENT_PWD' | translate }}</mat-label>
          <input
            [type]="type"
            autocomplete="off"
            formControlName="currentPassword"
            matInput
            placeholder="{{ 'CHANGE_PASSWORD.CURRENT_PWD' | translate }}" />
          @if (type === 'text') {
            <mat-icon
              (click)="peaky()"
              class="peek"
              >visibility_off
            </mat-icon>
          } @else if (type === 'password') {
            <mat-icon
              data-cy="change-password-peaky"
              (click)="peaky()"
              class="peek"
              >visibility
            </mat-icon>
          }
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="full density-minus-3">
          <mat-label>{{ 'CHANGE_PASSWORD.NEW_PWD' | translate }}</mat-label>
          <input
            [type]="type"
            autocomplete="off"
            formControlName="newPassword"
            matInput
            placeholder="{{ 'CHANGE_PASSWORD.NEW_PWD' | translate }}" />
          @if (type === 'text') {
            <mat-icon
              (click)="peaky()"
              class="peek"
              >visibility_off
            </mat-icon>
          } @else if (type === 'password') {
            <mat-icon
              (click)="peaky()"
              class="peek"
              >visibility
            </mat-icon>
          }
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="full density-minus-3">
          <mat-label>{{ 'CHANGE_PASSWORD.CONFIRM_NEW_PWD' | translate }}</mat-label>
          <input
            [type]="type"
            autocomplete="off"
            formControlName="confirmPassword"
            matInput
            placeholder="{{ 'CHANGE_PASSWORD.CONFIRM_NEW_PWD' | translate }}" />
          <mat-icon
            (click)="peaky()"
            *ngIf="type === 'text'"
            class="peek"
            >visibility_off
          </mat-icon>
          <mat-icon
            (click)="peaky()"
            *ngIf="type === 'password'"
            class="peek"
            >visibility
          </mat-icon>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="bottom-bar">
    <div class="line"></div>
    <div class="bottom-bar-buttons">
      <button
        data-cy="change-password-close"
        (click)="close()"
        class="cancel-button"
        mat-button
        type="button">
        {{ 'CONTACT_PREFERENCES.CANCEL' | translate }}
      </button>
      <button
        data-cy="change-password-save"
        [disabled]="(passwordForm.invalid && passwordForm.dirty) || passwordForm.pristine"
        color="primary"
        mat-raised-button
        type="submit">
        {{ 'CONTACT_PREFERENCES.SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
