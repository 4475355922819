<section>
  <form
    [formGroup]="pinForm"
    class="h-100 flex flex-col">
    <div class="pin-top">
      <div class="flex align-items-center greeting-title">
        <button
          mat-icon-button
          class="back-button"
          (click)="setSelectedPage('Main')">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <h1 class="title mb-0">{{ 'VOICE_MAIL_PREFERENCES.VOICEMAIL_PIN' | translate }}</h1>
      </div>
      <div class="section">
        <div class="content flex flex-col">
          <mat-form-field
            appearance="outline"
            class="density-minus-3 w-100">
            <mat-label>{{ 'VOICE_MAIL_PREFERENCES.NEW_PIN' | translate }}</mat-label>
            <input
              autocomplete="off"
              type="password"
              appOnlyNumberInput
              matInput
              formControlName="pin"
              [type]="isPinHide ? 'password' : 'text'" />
            <mat-icon
              matSuffix
              color="primary"
              (click)="toggleIsPinHide()">
              {{ isPinHide ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </mat-form-field>
          @if (
            (pinForm.controls.pin.value.length < 4 || pinForm.controls.pin.value.length > 10) &&
            (pinForm.controls.pin.dirty || pinForm.controls.pin.touched)
          ) {
            <mat-error class="mb-1">
              {{ getPinValidationMessage() }}
            </mat-error>
          }
          <mat-form-field
            appearance="outline"
            class="density-minus-3 w-100">
            <mat-label>{{ 'VOICE_MAIL_PREFERENCES.CONFIRM_PIN' | translate }}</mat-label>
            <input
              autocomplete="off"
              type="password"
              appOnlyNumberInput
              matInput
              formControlName="confirmPin"
              [type]="isPinHide ? 'password' : 'text'" />
            <mat-icon
              matSuffix
              color="primary"
              (click)="toggleIsPinHide()">
              {{ isPinHide ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </mat-form-field>
          @if (
            pinForm.controls.confirmPin.errors?.confirmedValidator &&
            (pinForm.controls.confirmPin.dirty || pinForm.controls.confirmPin.touched)
          ) {
            <mat-error>
              {{ 'VOICE_MAIL_PREFERENCES.PINS_DO_NOT_MATCH' | translate }}
            </mat-error>
          }
          @if (
            (pinForm.controls.confirmPin.value.length < 4 || pinForm.controls.confirmPin.value.length > 10) &&
            (pinForm.controls.confirmPin.dirty || pinForm.controls.confirmPin.touched)
          ) {
            <mat-error>
              {{ getPinValidationMessage() }}
            </mat-error>
          }
        </div>
      </div>
    </div>
    <div class="flex justify-content-end align-center bottom-bar mt-auto">
      <button
        class="cancel-button"
        mat-button
        type="button"
        (click)="setSelectedPage('Main')">
        {{ 'VOICE_MAIL_PREFERENCES.CANCEL' | translate }}
      </button>
      <button
        color="primary"
        mat-raised-button
        class="m-l-12 save-button"
        type="button"
        [disabled]="!pinForm.valid"
        (click)="save()">
        @if (processing) {
          <mat-spinner
            class="processing-spinner"
            color="accent">
          </mat-spinner>
        } @else {
          <span>{{ 'VOICE_MAIL_PREFERENCES.SAVE' | translate }}</span>
        }
      </button>
    </div>
  </form>
</section>
