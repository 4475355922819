<section>
  <div class="container">
    <h1>{{ 'CONTACT_PREFERENCES.CONTACTS' | translate }}</h1>
    <mat-divider class="m-t-16"></mat-divider>
    <div class="call-options flex">
      <div class="slider-item">
        <mat-slide-toggle
          class="slider"
          [disabled]="contactMatchingLoading"
          [formControl]="contactMatching"
          (change)="onChangeContactMatching()">
        </mat-slide-toggle>
        <div class="text">
          <span>{{ 'CONTACT_PREFERENCES.ENABLE_CONTACT_MATCHING' | translate }}</span>
        </div>
      </div>
      <span class="help-text">
        {{ 'CONTACT_PREFERENCES.CONTACT_MATCHING_TOOLTIP' | translate }}
        <!-- When disabled, the contact lookups for incoming calls will not be performed. The incoming Caller Name will be displayed instead. -->
      </span>
    </div>
    <div class="import-export-header">{{ 'CONTACT_PREFERENCES.EXPORT_CONTACTS' | translate }}</div>
    <mat-form-field
      appearance="outline"
      class="contact-type-form-field">
      <mat-label>{{ 'CONTACT_PREFERENCES.CONTACTS_TO_EXPORT' | translate }}</mat-label>
      <div class="flex">
        <mat-select [(value)]="selectedContactOption">
          <mat-option
            *ngIf="appConfigService.hasOfficeManagerRole$ | async"
            [value]="ContactType.Shared"
          >{{ ContactType.Shared | contactTypeLabel }}
          </mat-option
          >
          <mat-option [value]="ContactType.Personal">{{ ContactType.Personal | contactTypeLabel }}</mat-option>
        </mat-select>
      </div>
    </mat-form-field>
    <div class="export-btn">
      <button
        color="primary"
        mat-raised-button
        type="button"
        (click)="exportContacts()">
        <span>{{ 'CONTACT_PREFERENCES.EXPORT' | translate }}</span>
      </button>
    </div>
    <div class="import-export-header">{{ 'CONTACT_PREFERENCES.IMPORT_CONTACTS' | translate }}</div>
    <p class="import-text">
      {{ 'CONTACT_PREFERENCES.IMPORT_CONTACTS_DESC' | translate }}
      <!--
      The proper format for the CSV file can be obtained by exporting the contacts above. There is a 2MB limit for the
      import file.
      -->
    </p>
    <button
      class="action-button"
      (click)="handleClickOnImportContact()">
      <span class="action">{{ 'CONTACT_PREFERENCES.SELECT_A_FILE' | translate }}</span>
      <mat-icon color="primary">upload</mat-icon>
    </button>
  </div>
</section>
