<ng-template [ngIf]="!edit && !image && !isChangingPassword">
  <div class="flex space-between" *ngIf="profile">
    <div class="details">
      <div class="account-info">
        <h1>{{ 'PROFILE.PROFILE' | translate }}</h1>
        <h4>
          {{ 'PROFILE.MY_INFORMATION' | translate }}
          <i
            data-cy="profile-edit"
            (click)="edit = !edit"
            class="link edit">{{ 'PROFILE.EDIT' | translate }}</i>
        </h4>
        <div class="info">
          <span class="label">{{ 'PROFILE.NAME' | translate }}</span>
          <p class="profile-name">{{ profile.fullName }}</p>
        </div>
        <div
          *ngIf="profile.company"
          class="info">
          <span class="label">{{ 'PROFILE.COMPANY' | translate }}</span>
          <p class="company-name">{{ profile.company }}</p>
        </div>
        <div class="info" *ngIf="profile.title">
          <span class="label">{{ 'PROFILE.TITLE' | translate }}</span>
          <p class="title-name">{{ profile.title }}</p>
        </div>
        <div class="info">
          <span class="label">{{ 'PROFILE.EMAIL' | translate }}</span>
          <div *ngFor="let email of profile.emails" class="info-grid" data-cy="profile-emails">
            <span>
              {{ email.value }}
            </span>
            <span>{{ email.type | titlecase }}</span>
          </div>
        </div>
        <div class="info">
          <span class="label">{{ 'PROFILE.EXTENSION' | translate }}</span>
          <div class="info-grid">
            <span>
              {{ profile.ext }}
            </span>
          </div>
        </div>
        <div class="info">
          <span class="label">{{ 'PROFILE.PHONE_NUMBERS' | translate }}</span>
          <div *ngFor="let DID of DIDNumbers" class="info-grid" data-cy="profile-phone-number">
            <span>
              {{ DID | phoneNumber }}
            </span>
            <span>{{ 'PROFILE.DID' | translate }}</span>
          </div>

          <div *ngFor="let phone of profile.tels" class="info-grid" data-cy="profile-phone-number">
            <span>
              {{ phone.number | phoneNumber }}
            </span>
            <span
              *ngIf="phone.type===ExtensionTypes.SMS || phone.type===ExtensionTypes.PbxSms; else noneSMS">{{ phone.type | uppercase }}</span>
            <ng-template #noneSMS>
              <span>{{ phone.type | titlecase }}</span>
            </ng-template>
          </div>
        </div>
        <div class="info">
          <span class="label">{{ 'PROFILE.ADDRESS' | translate }}</span>
          <div class="info-grid" *ngFor="let address of profile.addresses" data-cy="profile-address">
            <span>
              {{ address.addressLine1 }} {{ address.city }} {{ address.state }} {{ address.postalCode }}
            </span>
            <span>{{ address.type | titlecase }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="avatar">
      <ng-template [ngIf]="(!profile.avatar)" [ngIfElse]="showAvatarBlock">
        <div class="bigCoin">
          <span data-cy="profile-icon-text">{{ getCoin() }}</span>
        </div>
        <h2 class="full-name">{{ profile.fullName }}</h2>
        <span
          data-cy="profile-choose-photo"
          class="link"
          (click)="file.click()">{{ 'PROFILE.CHOOSE_PROFILE_PHOTO' | translate }}</span>
      </ng-template>
      <ng-template #showAvatarBlock>
        <mat-spinner class="avatar-spinner" *ngIf="avatarLoading" />
        <div class="bigCoin" *ngIf="!avatarLoading">
          <img
            alt="avatar"
            [src]="profile.avatar" />
          <div
            (click)="file.click()"
            class="overlay">
            <mat-icon>add_a_photo</mat-icon>
          </div>
        </div>
        <button
          data-cy="profile-choose-photo"
          (click)="file.click()"
          color="primary"
          mat-raised-button>
          {{ 'PROFILE.CHOOSE_PHOTO' | translate }}
        </button>
        <button
          data-cy="profile-remove-photo"
          (click)="removeAvatar()"
          *ngIf="!profile?.avatar?.startsWith(defaultAvatarsBaseUrl)"
          mat-button>
          {{ 'PROFILE.REMOVE_PHOTO' | translate }}
        </button>
      </ng-template>
      <div class="img-format">
        <div class="file-types">
          {{ 'PROFILE.FILE_TYPES' | translate }}: jpg,png,jpeg,gif,svg
        </div>
        <div class="file-types">
          {{ 'PROFILE.SIZE_UP_TO' | translate }}
          <!-- Size: up to 1024 KB -->
        </div>
      </div>
    </div>
    <input
      data-cy="profile-input-file"
      #file
      appAvatar
      (changeEvent)="fileChangeEvent($event)" />
  </div>
  <div class="account-info account-time-format">
    <h4>{{ 'PROFILE.TIME_FORMAT' | translate }}</h4>
    <div class="info">
      <div class="flex align-items-center space-between m-b-8">
        <div class="label">{{ 'PROFILE.HOUR_FORMAT' | translate }}</div>
        <div class="label timezone">
          {{ 'PROFILE.TIMEZONE_COMPUTER_DEFAULT' | translate }}
          <!-- Timezone (Computer Default) -->
        </div>
      </div>
      <div class="flex space-between hour-format">
        <mat-radio-group color="primary" [(ngModel)]="selectedHourFormat" (change)="onHourFormatChange()">
          <mat-radio-button value="12">{{ 'PROFILE.12_HOUR' | translate }}</mat-radio-button>
          <mat-radio-button value="24">{{ 'PROFILE.24_HOUR' | translate }}</mat-radio-button>
        </mat-radio-group>
        <div class="fs-12 timezone m-t-8">{{ localTimezone }}</div>
      </div>
    </div>
  </div>
  <div class="account-info">
    <h4>
      {{ 'PROFILE.LANGUAGE' | translate }}
      <div class="beta-pill">
        Beta
      </div>
    </h4>
    <div class="info">
      <div class="flex space-between">
        <mat-select
          (selectionChange)="onUILanguageChanged($event.value)"
          value={{appTranslateService.selectedLanguage}}
        >
          @for(lang of availableLanguages; track lang.key) {
            <mat-option value="{{ lang.key }}">{{ lang.name }}</mat-option>
          }
        </mat-select>
      </div>
    </div>
  </div>
  <div class="account-settings account-info">
    <h4>{{ 'PROFILE.ACCOUNT_SETTINGS' | translate }}</h4>
    <span class="label">{{ 'PROFILE.SIGN_IN_PASSWORD' | translate }}
        <i
          data-cy="profile-change-password"
          (click)="isChangingPassword = true"
          class="edit">{{ 'PROFILE.CHANGE' | translate }}</i></span>
  </div>
</ng-template>

<ng-template [ngIf]="edit">
  <app-contact
    (cancelAction)="onEditCanceled()"
    (saveAction)="onDataSubmitted($event)"
    [loading]="editProfileLoading"
    [contact]="profile"
    [showSharedContact]="false"
    [title]="'PROFILE.EDIT_PROFILE' | translate"></app-contact>
</ng-template>

<ng-template [ngIf]="image">
  <app-transform-image [title]="'Set Profile Photo'" [imageChangedEvent]="imageChangedEvent"
                       (cancelEvent)="image = false"
                       (saveEvent)="save($event)" />
</ng-template>

<app-change-password
  (passwordChange)="onPasswordChange($event)"
  (cancelChanges)="onPasswordChangeCanceled()"
  *ngIf="isChangingPassword"></app-change-password>
