<ng-container *ngIf="!addingRule && !addingTimeFrame">
  <div
    class="answering-rules-container"
    [ngClass]="{ 'audio-settings-container-overflow': false }">
    <div class="header">
      <h1 class="header-title">{{ 'ANSWERING_RULES_PREFERENCES.ANSWERING_RULES' | translate }}</h1>
    </div>
    <div class="subheader">{{ 'ANSWERING_RULES_PREFERENCES.RING_OPTIONS' | translate }}</div>
    <div class="ring-options">
      <div class="label">{{ 'ANSWERING_RULES_PREFERENCES.RING_FOR' | translate }}</div>
      <mat-form-field
        class="input-40"
        style="width: 240px"
        appearance="outline">
        <input
          matInput
          #delayInput
          type="number"
          min="5"
          max="7200"
          [formControl]="ringDuration" />
        <span
          *ngIf="delayInput.value && delayInput.value.length > 0"
          [style]="'position: relative; left: ' + (-138 + 10 * delayInput.value.length) + 'px;'"
          matTextSuffix>
          {{ 'ANSWERING_RULES_PREFERENCES.SECONDS' | translate }}
        </span>
        <mat-hint class="hint small-text">{{ 'ANSWERING_RULES_PREFERENCES.VALID_RANGE_SECONDS' | translate }}</mat-hint>
      </mat-form-field>
    </div>
    <div class="return-busy-if-on-call-options">
      <div class="label">{{ 'ANSWERING_RULES_PREFERENCES.RETURN_BUSY_CALL' | translate }}</div>
      <div class="slide-toggle">
        <mat-slide-toggle [formControl]="returnBusyIfOnCall"></mat-slide-toggle>
        <mat-hint class="hint small-text"
          >{{ 'ANSWERING_RULES_PREFERENCES.ALLOWS_USER_CONTROL_MULTIPLE_CALLS' | translate }}
        </mat-hint>
      </div>
    </div>
    <div class="ring-options">
      <div class="label">{{ 'ANSWERING_RULES_PREFERENCES.AUTO_ANSWER_INCOMING_CALLS' | translate }}</div>
      <div class="slide-toggle">
        <mat-slide-toggle
          [checked]="autoAnswerIncomingCalls.value"
          [formControl]="autoAnswerIncomingCalls">
        </mat-slide-toggle>
        <mat-hint class="small-text"
          >{{ 'ANSWERING_RULES_PREFERENCES.INCOMING_CALLS_AUTOMATICALLY_ANSWERED' | translate }}
        </mat-hint>
      </div>
    </div>
    <div class="ring-options">
      <div class="label">{{ 'ANSWERING_RULES_PREFERENCES.AUTO_ANSWER_TIME' | translate }}</div>
      <mat-form-field
        class="input-40"
        style="width: 240px"
        appearance="outline">
        <input
          matInput
          #delayInputAnswer
          type="number"
          min="0"
          max="7200"
          [formControl]="autoAnswerTime" />
        <span
          [style]="'position: relative; left: ' + (-138 + 10 * delayInputAnswer.value.length) + 'px;'"
          matTextSuffix
          >{{ 'ANSWERING_RULES_PREFERENCES.SECONDS' | translate }}</span
        >
        <mat-hint class="hint small-text"
          >{{ 'ANSWERING_RULES_PREFERENCES.INCOMING_CALLS_ANSWERED_IMMEDIATELY' | translate }}
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="subheader">{{ 'ANSWERING_RULES_PREFERENCES.ANSWERING_RULES' | translate }}</div>
    <div
      *ngIf="answeringRules.length === 0 && (answeringRulesService.answeringRulesLoading$ | async) === false"
      class="text">
      {{ 'ANSWERING_RULES_PREFERENCES.WHEN_NO_ANSWERING_RULES' | translate }}
    </div>
    <div
      *ngIf="answeringRulesService.answeringRulesLoading$ | async"
      class="spinner-wrapper">
      <mat-spinner diameter="48"></mat-spinner>
    </div>
    <ng-container *ngIf="(answeringRulesService.answeringRulesLoading$ | async) === false">
      <div
        class="answering-rules"
        cdkDropList
        cdkScrollable
        (cdkDropListDropped)="drop($event)">
        <app-answering-rule
          *ngFor="let rule of answeringRules; index as i"
          cdkDrag
          [answeringRule]="rule"
          (edit)="editRule(i)"
          (delete)="confirmDeleteRule(i)">
        </app-answering-rule>
      </div>
      <div
        *ngIf="appConfigService.features[AppFeature.ModifyAnsweringRules]"
        class="add-option"
        (click)="addingRule = !addingRule">
        <div>{{ 'ANSWERING_RULES_PREFERENCES.ADD_RULE' | translate }}</div>
        <mat-icon>add_box</mat-icon>
      </div>
    </ng-container>

    <div class="subheader time-frames-section">
      <div>{{ 'ANSWERING_RULES_PREFERENCES.TIME_FRAMES' | translate }}</div>
      <mat-radio-group
        color="primary"
        [(ngModel)]="filtered"
        (change)="filterTimeFrames()">
        <mat-radio-button
          disableRipple
          [value]="false">
          {{ 'ANSWERING_RULES_PREFERENCES.ALL' | translate }}
        </mat-radio-button>
        <mat-radio-button
          disableRipple
          [value]="true"
          [style]="'margin-left: 16px'">
          {{ 'ANSWERING_RULES_PREFERENCES.PERSONAL' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div
      *ngIf="answeringRulesService.timeFramesLoading$ | async"
      class="spinner-wrapper">
      <mat-spinner diameter="48"></mat-spinner>
    </div>
    <ng-container *ngIf="(answeringRulesService.timeFramesLoading$ | async) === false">
      <div class="time-frames-header small-text">
        <span>{{ 'ANSWERING_RULES_PREFERENCES.NAME' | translate }}</span>
        <span>{{ 'ANSWERING_RULES_PREFERENCES.DESCRIPTION' | translate }}</span>
        <span>{{ 'ANSWERING_RULES_PREFERENCES.OWNER' | translate }}</span>
      </div>
      <div
        *ngIf="(answeringRulesService.timeFramesLoading$ | async) === false"
        class="time-frames">
        <div
          class="time-frame"
          *ngFor="let timeFrame of filteredTimeFrames; index as i">
          <div>{{ timeFrame.time_frame }}</div>
          <div>{{ timeFrameDescription(timeFrame) }}</div>
          <div>
            @if (timeFrame.owner === '*') {
              {{ 'ANSWERING_RULES_PREFERENCES.SHARED' | translate }}
            } @else {
              {{ 'ANSWERING_RULES_PREFERENCES.PERSONAL' | translate }}
            }
          </div>
          <div
            class="buttons"
            [ngClass]="{
              disabled: timeFramesEditingDisabled,
            }">
            <mat-icon
              *ngIf="timeFrame.owner !== '*' || answeringRulesService.isCurrentUserOfficeManager"
              (click)="editOrCopyTimeFrame(i)">
              edit_outline
            </mat-icon>
            <mat-icon
              *ngIf="timeFrame.owner === '*' && !answeringRulesService.isCurrentUserOfficeManager"
              (click)="editOrCopyTimeFrame(i)">
              content_copy
            </mat-icon>
            <mat-icon
              [ngClass]="{
                disabled:
                  timeFrame.in_use || (timeFrame.owner === '*' && !answeringRulesService.isCurrentUserOfficeManager),
              }"
              [matTooltip]="deleteIconTooltipText(timeFrame)"
              matTooltipShowDelay="500"
              (click)="
                !timeFrame.in_use &&
                  (timeFrame.owner !== '*' || answeringRulesService.isCurrentUserOfficeManager) &&
                  confirmDeleteTimeFrame(i)
              ">
              delete_outline
            </mat-icon>
          </div>
        </div>
      </div>
      <div
        class="add-option"
        [ngClass]="{
          disabled: timeFramesEditingDisabled,
        }"
        (click)="addingTimeFrame = !addingTimeFrame">
        <div>{{ 'ANSWERING_RULES_PREFERENCES.ADD_TIME_FRAME' | translate }}</div>
        <mat-icon>add_box</mat-icon>
      </div>
    </ng-container>
  </div>
  <div class="main-buttons">
    <button
      mat-button
      type="button"
      color="primary"
      (click)="onCancel()">
      {{ 'ANSWERING_RULES_PREFERENCES.CANCEL' | translate }}
    </button>
    <button
      mat-raised-button
      type="button"
      color="primary"
      [disabled]="
        (!reordered &&
          autoAnswerIncomingCalls.pristine &&
          ringDuration.pristine &&
          autoAnswerTime.pristine &&
          returnBusyIfOnCall.pristine) ||
        ringDuration.invalid ||
        loading
      "
      (click)="onSave()">
      <mat-spinner
        diameter="24"
        *ngIf="loading"></mat-spinner>
      <span *ngIf="!loading">{{ 'ANSWERING_RULES_PREFERENCES.SAVE' | translate }}</span>
    </button>
  </div>
</ng-container>

<app-add-answering-rule
  *ngIf="addingRule"
  [answeringRule]="actionRule"
  [timeFramesUsedByMe]="timeFramesUsedByMe"
  (cancelChanges)="onSubpanelCancel()">
</app-add-answering-rule>

<app-add-timeframe
  *ngIf="addingTimeFrame"
  [timeFrame]="actionTimeFrame"
  (cancelChanges)="onSubpanelCancel()">
</app-add-timeframe>
