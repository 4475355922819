import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { Integration } from '@app/preferences/models/integrations.models';
import { InitialsPipe } from '@app/shared/pipes/initials.pipe';

@Component({
  selector: 'app-integration-favicon',
  standalone: true,
  templateUrl: './integration-favicon.component.html',
  imports: [CommonModule, InitialsPipe],
  styleUrls: ['./integration-favicon.component.scss'],
})
export class IntegrationFaviconComponent {
  integration = input.required<Integration>();
}
