import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppTranslateService } from '@app/core/services/app-translate.service';
import { RegisterTelHandlerDialogComponent } from '@app/shared/components/register-tel-handler-dialog/register-tel-handler-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LocalStorageService } from '../../../services/local-storage.service';
import { ProtocolHandlerService } from '../../../services/protocol-handler.service';
import { PwaService } from '../../../services/pwa.service';

@UntilDestroy()
@Component({
  selector: 'app-register-tel-handler',
  templateUrl: './register-tel-handler.component.html',
  styleUrls: ['./register-tel-handler.component.scss'],
})
export class RegisterTelHandlerComponent implements OnInit {
  static readonly protocolUrl = '/calls/history/%s';
  static readonly dismissLocalStorageKey = 'dismissRegisterTelHandler';

  open: boolean = false;
  pwaInstalled = true;

  constructor(
    private pwaService: PwaService,
    private dialog: MatDialog,
    private protocolHandlerService: ProtocolHandlerService,
    private storage: LocalStorageService,
    private appTranslateService: AppTranslateService
  ) {}

  ngOnInit() {
    const dismissBanner = this.storage.get<boolean>(RegisterTelHandlerComponent.dismissLocalStorageKey);
    if (dismissBanner) {
      return;
    }
    this.pwaService.pwaInstalled$.pipe(untilDestroyed(this)).subscribe((pwaInstalled) => {
      this.pwaInstalled = pwaInstalled;
    });
    if (!this.protocolHandlerService.isRegistered('tel')) {
      this.open = true;
    }
  }

  async handleActionClick(action: string) {
    switch (action) {
      case this.appTranslateService.instant('BANNERS.LEARN_MORE_ACTION'): {
        window.open('https://docs.connectuc.io/en/articles/14-use-one-click-calling-with-connectuc', '_blank');
        break;
      }
      case this.appTranslateService.instant('BANNERS.INSTALL_APP_ACTION'): {
        if (await this.pwaService.promptInstall()) {
          this.open = false;
          this.dialog.open(RegisterTelHandlerDialogComponent, {
            data: {
              protocolUrl: RegisterTelHandlerComponent.protocolUrl,
            },
            disableClose: true,
          });
        }
        break;
      }
      case this.appTranslateService.instant('BANNERS.REGISTER_NOW_ACTION'): {
        this.registerTelHandler();
        break;
      }
    }
  }

  dismiss() {
    this.storage.set(RegisterTelHandlerComponent.dismissLocalStorageKey, true);
    this.open = false;
  }

  registerTelHandler() {
    // used an array to make it easier to add more handlers in the future
    // in the case of adding more handlers, this could be moved to a service
    try {
      this.protocolHandlerService.registerProtocolHandler('tel', RegisterTelHandlerComponent.protocolUrl);
      this.open = false;
    } catch (error) {
      console.log('registerProtocolHandler error', error);
    }
  }
}
