import { CallDirection } from '@app/call-history/models/call-history.models';

export enum CallEventTypes {
  /** CallAnswered is a domain event and likely needs to be filtered by aor to get events just for this user */
  CallAnswered = 'CallAnswered',
  CallRang = 'CallRang',
  CallStarted = 'CallStarted',
  CallEnded = 'CallEnded',
}

export interface BaseCallEventData {
  orig_callid: string;
  term_callid: string;
  aor: string;
  status?: string;
  trigger?: string;
  callTag?: string;
  fromTag?: string;
  toTag?: string;
}

export interface CallAnsweredEventData extends BaseCallEventData {
  caller_id: string;
  caller_name: string;
  dialed: string;
  time_start: string;
  to: string;
  from: string;
  subscriberName: string;
  ivr: boolean;
  callDirection?: CallDirection;
}

export interface CallStartedEventData extends BaseCallEventData {
  caller_id: string;
  caller_name: string;
  time_start: string;
  to: string;
  status: string;
}

export interface CallRangEventData extends BaseCallEventData {
  caller_id: string;
  caller_name: string;
  time_start: string;
  to: string;
  status: string;
}

export interface CallStartedEvent {
  event: CallEventTypes.CallStarted;
  payload: CallStartedEventData;
}

export interface CallRangEvent {
  event: CallEventTypes.CallRang;
  payload: CallRangEventData;
}

export interface CallAnsweredEvent {
  event: CallEventTypes.CallAnswered;
  payload: CallAnsweredEventData;
}

export interface CallEndedEvent {
  event: CallEventTypes.CallEnded;
  payload: BaseCallEventData;
}
