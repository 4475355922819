import { CallDirection } from '@app/call-history/models/call-history.models';

export interface Integration {
  id: number;
  display_name: string;
  description: string;
  favicon?: string;
  enabled: boolean;

  /** If true, opens on incoming call */
  auto_pop: boolean;

  /** If true, opens on outgoing call */
  auto_dial: boolean;

  launch_url: string;
  lambda: string | null;
  launch_scenarios: {
    onIncomingCall: {
      value: boolean;
      event: LaunchAutoPopType;
    };
    onOutboundCall: {
      value: boolean;
    };
    silentWebCall: {
      value: true;
    };
    onInboundSMS: {
      value: boolean;
      event: 1;
    };
  };
  variables: {
    customer_id: string;
  };
}

export enum LaunchAutoPopType {
  ON_RING = 0,
  ON_ANSWER = 1,
}

export type WebPopCallObject = {
  phone_number: string;
  caller_ID: string;
  caller_name: string | '';
  call_direction: CallDirection;
  date_time: string;
  call_ID: string;
  dialed_number: string | null;
  dialed_number_10_digit: string | null;
  caller_ID_10_digit: string;
  customer_id?: string;
};
