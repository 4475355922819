<div
  #container
  class="container">
  <div class="add-rule-container">
    <h1>
      <mat-icon (click)="close()">chevron_left</mat-icon>
      {{ pageTitle }}
    </h1>

    <form
      class="form-container"
      [formGroup]="ruleForm">
      <div class="section">
        <div class="subheader line">
          {{ 'ANSWERING_RULES_PREFERENCES.ENABLE_RULE' | translate }}
          <mat-slide-toggle formControlName="enabled"></mat-slide-toggle>
        </div>
      </div>

      <div class="section">
        <div class="subheader line">
          {{ 'ANSWERING_RULES_PREFERENCES.TIME_FRAME' | translate }}
          <mat-radio-group
            *ngIf="answeringRule === undefined && appConfigService.features[AppFeature.ModifyTimeFrames]"
            color="primary"
            [formControl]="filterCtrl"
            (change)="filterTimeFrames()">
            <mat-radio-button
              disableRipple
              [value]="false">
              {{ 'ANSWERING_RULES_PREFERENCES.ALL' | translate }}
            </mat-radio-button>
            <mat-radio-button
              disableRipple
              [value]="true"
              [style]="'margin-left: 16px'">
              {{ 'ANSWERING_RULES_PREFERENCES.PERSONAL' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field
          class="time-frame-select input-40"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always">
          <mat-label
            *ngIf="answeringRule === undefined"> {{ 'ANSWERING_RULES_PREFERENCES.SELECT_A_TIME_FRAME' | translate }}
          </mat-label>
          <mat-select
            #timeFrameSelect
            formControlName="timeFrame"
            placeholder="{{ 'ANSWERING_RULES_PREFERENCES.SELECT' | translate }}"
          >
            <mat-option [value]="newTimeFrameOptionString">
              {{ 'ANSWERING_RULES_PREFERENCES.NEW_TIME_FRAME' | translate }}
            </mat-option>
            <mat-option
              *ngFor="let tf of filteredTimeFrames"
              [disabled]="timeFramesUsedByMe.includes(tf.time_frame)"
              [value]="tf.time_frame">
              {{ tf.time_frame }}
              <mat-icon
                style="position: absolute; right: 22px"
                [ngClass]="{
                  disabled:
                    (tf.in_use && !timeFramesUsedByMe.includes(tf.time_frame)) ||
                    (tf.owner === '*' && !answeringRulesService.isCurrentUserOfficeManager)
                }"
                (click)="deleteTimeFrame($event, tf.time_frame)">
                delete_outline
              </mat-icon>
            </mat-option>
            <mat-option *ngIf="defaultRule" value="*">
              {{ 'ANSWERING_RULES_PREFERENCES.DEFAULT' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-add-timeframe
          *ngIf="timeFrameSelect.value === newTimeFrameOptionString"
          #timeFrame
          [standalone]="false"
          [(invalid)]="invalidTimeFrame">
        </app-add-timeframe>
      </div>

      <div class="section">
        <div class="subheader line">
          {{ 'ANSWERING_RULES_PREFERENCES.CALL_SCREENING' | translate }}
          <mat-slide-toggle formControlName="callScreening"></mat-slide-toggle>
        </div>
        <div class="text">
          {{ 'ANSWERING_RULES_PREFERENCES.CALL_SCREENING_DESC' | translate }}
        </div>
      </div>

      <div class="section">
        <div class="subheader line">
          {{ 'ANSWERING_RULES_PREFERENCES.RINGING_BEHAVIOR' | translate }}
        </div>
        <div class="text">
          {{ 'ANSWERING_RULES_PREFERENCES.RINGING_BEHAVIOR_DESC' | translate }}
        </div>
        <mat-form-field
          class="behaviour-select input-40"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>
            {{ 'ANSWERING_RULES_PREFERENCES.SELECT_RINGING_BEHAVIOR' | translate }}
          </mat-label>
          <mat-select
            #behaviourSelection
            placeholder="{{ 'ANSWERING_RULES_PREFERENCES.SELECT' | translate }}"
            [formControl]="ruleType"
            (selectionChange)="behaviourSelectionChange($event)">
            <mat-option [value]="AnsweringRuleType.ForwardAllCalls">
              {{ 'ANSWERING_RULES_PREFERENCES.FORWARD_ALL_CALLS' | translate }}
            </mat-option>
            <mat-option [value]="AnsweringRuleType.DoNotDisturb">
              {{ 'ANSWERING_RULES_PREFERENCES.DO_NOT_DISTURB' | translate }}
            </mat-option>
            <mat-option [value]="AnsweringRuleType.OnlyPrimaryDeskphone">
              {{ 'ANSWERING_RULES_PREFERENCES.ONLY_PRIMARY_DESKPHONE' | translate }}
            </mat-option>
            <mat-option [value]="AnsweringRuleType.SimultaneousRing">
              {{ 'ANSWERING_RULES_PREFERENCES.SIMULTANEOUS_RING' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="behaviourSelection.value === AnsweringRuleType.ForwardAllCalls">
          <div class="text">{{ 'ANSWERING_RULES_PREFERENCES.ONLY_PRIMARY_DESKPHONE_DESCRIPTION' | translate }}</div>
          <mat-form-field
            class="forward-field input-40"
            hideRequiredMarker
            appearance="outline"
            floatLabel="always">
            <mat-label>{{ 'ANSWERING_RULES_PREFERENCES.START_TYPING_CONTACT_NAME' | translate }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ 'ANSWERING_RULES_PREFERENCES.PLACEHOLDER_CONTACT_NAME_OR_NUMBER' | translate }}"
              formControlName="forwardAlwaysDestination"
              [matAutocomplete]="autoAlways"
              (focus)="onInputFieldFocus(AutocompleteSource.Always)" />
            <mat-autocomplete #autoAlways="matAutocomplete">
              <mat-option
                *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                [value]="destination.description">
                {{ destination.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="behaviourSelection.value === AnsweringRuleType.DoNotDisturb">
          <div class="dnd-text text">{{ 'ANSWERING_RULES_PREFERENCES.NO_PHONE_RING_DESCRIPTION' | translate }}</div>
        </ng-container>

        <ng-container *ngIf="behaviourSelection.value === AnsweringRuleType.OnlyPrimaryDeskphone">
          <div class="text">{{ 'ANSWERING_RULES_PREFERENCES.ONLY_PRIMARY_DESKPHONE_DESCRIPTION' | translate }}</div>
        </ng-container>

        <ng-container *ngIf="behaviourSelection.value === AnsweringRuleType.SimultaneousRing">
          <div class="text">{{ 'ANSWERING_RULES_PREFERENCES.SIMULTANEOUS_RING_DESCRIPTION' | translate }}</div>
          <div class="ring-controls">
            <div class="ring-options">
              <mat-checkbox
                formControlName="simRingUsersExtension"
                [disableRipple]="true"
                [color]="'primary'">
                {{ 'ANSWERING_RULES_PREFERENCES.INCLUDE_USERS_EXTENSION' | translate }}
              </mat-checkbox>
              <mat-checkbox
                formControlName="simRingAllDevices"
                [disableRipple]="true"
                [color]="'primary'">
                {{ 'ANSWERING_RULES_PREFERENCES.RING_ALL_USERS_PHONES' | translate }}
              </mat-checkbox>
              <mat-checkbox
                formControlName="simRingConfirmOffnet"
                [disableRipple]="true"
                [color]="'primary'"
              >{{ 'ANSWERING_RULES_PREFERENCES.ANSWER_CONFIRMATION_OFFNET' | translate }}
              </mat-checkbox
              >
            </div>
            <div class="ring-rules">
              <ng-container formArrayName="simRingOtherDestinations">
                <div
                  class="ring-rule"
                  [ngClass]="{ editing: editSimRingDestIndex === i }"
                  *ngFor="let _ of otherDestinations.controls; index as i">
                  <ng-container [formGroupName]="i">
                    <mat-form-field
                      class="number input-40"
                      appearance="outline">
                      <input
                        matInput
                        [readonly]="editSimRingDestIndex !== i"
                        type="text"
                        formControlName="destination" />
                    </mat-form-field>
                    <mat-icon class="clock-icon">schedule</mat-icon>
                    <mat-form-field
                      class="delay input-40"
                      appearance="outline">
                      <input
                        #delayInput
                        matInput
                        [readonly]="editSimRingDestIndex !== i"
                        type="number"
                        min="0"
                        max="60"
                        formControlName="delay" />
                      <span
                        [style]="'position: relative; left: ' + (-47 + 8 * delayInput.value.length) + 'px;'"
                        matTextSuffix>
                        {{ 'ANSWERING_RULES_PREFERENCES.SEC' | translate }}
                      </span>
                    </mat-form-field>
                    <mat-icon
                      class="edit-icon"
                      [ngClass]="{ editing: editSimRingDestIndex === i }"
                      (click)="editSimRingDestination(i)">
                      edit_outline
                    </mat-icon>
                    <mat-icon
                      class="delete-icon"
                      [ngClass]="{ editing: editSimRingDestIndex === i }"
                      (click)="deleteSimRingDestination(i)">
                      delete_outline
                    </mat-icon>
                    <mat-icon
                      class="done-icon"
                      [ngClass]="{ editing: editSimRingDestIndex === i, disabled: otherDestinations.at(i).invalid }"
                      (click)="editSimRingDestinationDone()">
                      done_outline
                    </mat-icon>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div
              class="new-ring-rule"
              [ngClass]="{ space: otherDestinations.length > 0 }">
              <mat-form-field
                class="number input-40"
                hideRequiredMarker
                appearance="outline"
                floatLabel="always">
                <mat-label>{{ 'ANSWERING_RULES_PREFERENCES.ADD_NUMBER_SIM_RING' | translate }}</mat-label>
                <input
                  matInput
                  #simRingDestination
                  type="text" />
              </mat-form-field>
              <mat-form-field
                class="delay input-40"
                hideRequiredMarker
                appearance="outline"
                floatLabel="always">
                <mat-label>{{ 'ANSWERING_RULES_PREFERENCES.RING_DELAY' | translate }}</mat-label>
                <input
                  matInput
                  #simRingDelay
                  value="0"
                  min="0"
                  max="60"
                  type="number" />
              </mat-form-field>
              <mat-icon
                [ngClass]="{
                  disabled:
                    simRingDestination.value.length === 0 ||
                    simRingDelay.value.length === 0 ||
                    Number(simRingDelay.value) < 0 ||
                    Number(simRingDelay.value) > 60
                }"
                (click)="addSimRingDestination()"
                >add_box</mat-icon
              >
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container
        *ngIf="
          behaviourSelection.value === AnsweringRuleType.SimultaneousRing ||
          behaviourSelection.value === AnsweringRuleType.OnlyPrimaryDeskphone
        ">
        <div class="section">
          <div class="subheader line">{{ 'ANSWERING_RULES_PREFERENCES.CALL_FORWARDING' | translate }}</div>
          <div class="text">{{ 'ANSWERING_RULES_PREFERENCES.FORWARD_ALL_CALLS' | translate }}</div>
          <div class="forwarding-options">
            <div class="item">
              <div>
                <mat-checkbox
                  formControlName="forwardOnActiveEnabled"
                  [color]="'primary'"
                  [disableRipple]="true"
                  (change)="setCallForwardInputsState(['forwardOnActiveDestination'], $event.checked)">
                  {{ 'ANSWERING_RULES_PREFERENCES.ACTIVE' | translate }}
                </mat-checkbox>
                <mat-icon
                  matTooltip="{{ 'ANSWERING_RULES_PREFERENCES.ACTIVE_FORWARDING_TOOLTIP' | translate }}"
                  matTooltipPosition="above">
                  info_outline
                </mat-icon>
              </div>
              <mat-form-field
                class="input-40"
                hideRequiredMarker
                appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'ANSWERING_RULES_PREFERENCES.START_TYPING' | translate }}"
                  formControlName="forwardOnActiveDestination"
                  [matAutocomplete]="autoActive"
                  (focus)="onInputFieldFocus(AutocompleteSource.OnActive)" />
                <mat-autocomplete #autoActive="matAutocomplete">
                  <mat-option
                    *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                    [value]="destination.description">
                    {{ destination.description }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div
              *ngIf="behaviourSelection.value !== AnsweringRuleType.SimultaneousRing"
              class="item">
              <div>
                <mat-checkbox
                  formControlName="forwardBusyEnabled"
                  [color]="'primary'"
                  [disableRipple]="true"
                  (change)="setCallForwardInputsState(['forwardBusyDestination'], $event.checked)">
                  {{ 'ANSWERING_RULES_PREFERENCES.WHEN_BUSY' | translate }}
                </mat-checkbox>
                <mat-icon
                  matTooltip="{{ 'ANSWERING_RULES_PREFERENCES.WHEN_BUSY_TOOLTIP' | translate }}"
                  matTooltipPosition="above">
                  info_outline
                </mat-icon>
              </div>
              <mat-form-field
                class="input-40"
                hideRequiredMarker
                appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'ANSWERING_RULES_PREFERENCES.START_TYPING' | translate }}"
                  formControlName="forwardBusyDestination"
                  [matAutocomplete]="autoBusy"
                  (focus)="onInputFieldFocus(AutocompleteSource.Busy)" />
                <mat-autocomplete #autoBusy="matAutocomplete">
                  <mat-option
                    *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                    [value]="destination.description">
                    {{ destination.description }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="item">
              <div>
                <mat-checkbox
                  formControlName="forwardNoAnswerEnabled"
                  [color]="'primary'"
                  [disableRipple]="true"
                  (change)="setCallForwardInputsState(['forwardNoAnswerDestination'], $event.checked)">
                  {{ 'ANSWERING_RULES_PREFERENCES.WHEN_UNANSWERED' | translate }}
                </mat-checkbox>
                <mat-icon
                  matTooltip="{{ 'ANSWERING_RULES_PREFERENCES.UNANSWERED_TOOLTIP' | translate }}"
                  matTooltipPosition="above">
                  info_outline
                </mat-icon>
              </div>
              <mat-form-field
                class="input-40"
                hideRequiredMarker
                appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'ANSWERING_RULES_PREFERENCES.START_TYPING' | translate }}"
                  formControlName="forwardNoAnswerDestination"
                  [matAutocomplete]="autoNoAnswer"
                  (focus)="onInputFieldFocus(AutocompleteSource.NoAnswer)" />
                <mat-autocomplete #autoNoAnswer="matAutocomplete">
                  <mat-option
                    *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                    [value]="destination.description">
                    {{ destination.description }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="item">
              <div>
                <mat-checkbox
                  formControlName="forwardOfflineEnabled"
                  [color]="'primary'"
                  [disableRipple]="true"
                  (change)="setCallForwardInputsState(['forwardOfflineDestination'], $event.checked)">
                  {{ 'ANSWERING_RULES_PREFERENCES.WHEN_OFFLINE' | translate }}
                </mat-checkbox>
                <mat-icon
                  matTooltip="{{ 'ANSWERING_RULES_PREFERENCES.OFFLINE_TOOLTIP' | translate }}"
                  matTooltipPosition="above">
                  info_outline
                </mat-icon>
              </div>
              <mat-form-field
                class="input-40"
                hideRequiredMarker
                appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'ANSWERING_RULES_PREFERENCES.START_TYPING' | translate }}"
                  formControlName="forwardOfflineDestination"
                  [matAutocomplete]="autoOffline"
                  (focus)="onInputFieldFocus(AutocompleteSource.Offline)" />
                <mat-autocomplete #autoOffline="matAutocomplete">
                  <mat-option
                    *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                    [value]="destination.description">
                    {{ destination.description }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div
    *ngIf="editingDisabled"
    class="grey-mask"
    #greyMask></div>
</div>

<div class="main-buttons">
  <button mat-button type="button" color="primary" (click)="cancelEvent()">
    {{ 'ANSWERING_RULES_PREFERENCES.CANCEL' | translate }}
  </button>
  <button
    mat-raised-button
    type="button"
    color="primary"
    [disabled]="ruleForm.invalid || ruleForm.pristine || editSimRingDestIndex !== -1 || invalidTimeFrame || saving"
    (click)="saveEvent()"
  >
    <mat-spinner diameter="24" *ngIf="saving"></mat-spinner>
    <span *ngIf="!saving">{{ 'ANSWERING_RULES_PREFERENCES.SAVE' | translate }}</span>
  </button>
</div>
