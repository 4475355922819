<div class="container">
  @if (!!lambdaOutput().errorMessage) {
    <div class="error-message">
      <h4>
        <mat-icon class="error-icon">error_outline</mat-icon>
        Run time error
      </h4>
      <p>{{ lambdaOutput().errorMessage }}</p>
    </div>
  }

  <div class="content">
    <ng-container
      *ngTemplateOutlet="
        keyValueTemplate;
        context: { key: 'Date & Time', value: lambdaOutput().createdAt | date: 'MMM d  hh:mm' }
      ">
    </ng-container>

    @if (!!lambdaOutput().callObj) {
      @for (item of lambdaOutput().callObj | keyvalue; track $index) {
        <ng-container *ngTemplateOutlet="keyValueTemplate; context: { key: item.key, value: item.value }">
        </ng-container>
      }
    }
    <button
      class="download-btn"
      (click)="downloadFileBtnClicked.emit(lambdaOutput())">
      {{ 'INTEGRATIONS_PREFERENCES.DOWNLOAD_LOG_FILE' | translate }}
    </button>
  </div>
</div>

<ng-template
  #keyValueTemplate
  let-key="key"
  let-value="value">
  <div class="key-value">
    <span class="key">{{ key }}</span>
    <span class="value">{{ value || 'empty' }}</span>
  </div>
</ng-template>
