<div class="container">
  <div class="side-bar">
    <ul>
      <li
        (click)="currentSelect = item"
        *ngFor="let item of iterableList()"
        [ngClass]="{ 'is-active': currentSelect === item }">
        <div>{{ 'PREF_NAV.' + item.replaceAll(' ', '_').toUpperCase() | translate }}</div>
      </li>
    </ul>
    <div
      class="item debug-container"
      *ngIf="debugVersion">
      <div class="text-center">
        <span> {{ 'PREF_NAV.VERSION' | translate }}: </span>
        <span>{{ debugVersion }} </span>
      </div>
      <img
        src="../../../../assets/icons/update_24dp.svg"
        height="24"
        width="24"
        class="update-btn"
        (click)="onUpdate()"
        alt="" />
      <div
        *ngIf="updateRejected"
        class="update-badge"></div>
    </div>
  </div>
  <div
    class="settings"
    [ngSwitch]="currentSelect">
    <app-profile
      *ngSwitchCase="SettingType.Profile"
      [profile]="profile"></app-profile>
    <app-audio
      *ngSwitchCase="SettingType.Audio"
      #page></app-audio>
    <app-notification
      *ngSwitchCase="SettingType.Notifications"
      #page></app-notification>
    <app-contacts
      #page
      *ngSwitchCase="SettingType.Contacts"></app-contacts>
    <app-video
      *ngSwitchCase="SettingType.Video"
      #page></app-video>
    <app-answering-rules
      *ngSwitchCase="SettingType.AnsweringRules"
      #page
      (cancelChanges)="currentSelect = SettingType.Profile"></app-answering-rules>
    <app-blocked-numbers
      *ngSwitchCase="SettingType.BlockedNumbers"
      #page></app-blocked-numbers>
    <app-music-on-hold
      *ngSwitchCase="SettingType.MusicOnHold"
      #page></app-music-on-hold>
    <app-integrations
      *ngSwitchCase="SettingType.Integrations"
      #page></app-integrations>
    <!-- <app-hid
      *ngSwitchCase="SettingType.Headset"
      #page></app-hid> -->
    <app-voicemail
      *ngSwitchCase="SettingType.Voicemail"
      (voicemailSettingsSaved)="currentSelect = SettingType.Profile"
      #page></app-voicemail>
    <ng-container
      *ngIf="
        currentSelect !== SettingType.BlockedNumbers &&
        currentSelect !== SettingType.Contacts &&
        currentSelect !== SettingType.Integrations &&
        currentSelect !== SettingType.Voicemail &&
        currentSelect !== SettingType.AnsweringRules
      ">
      <div
        class="flex justify-content-end align-center bottom-bar"
        *ngIf="currentPage">
        <button
          class="cancel-button"
          (click)="currentSelect = SettingType.Profile"
          mat-button
          type="button">
          {{ 'CONTACT_PREFERENCES.CANCEL' | translate }}
        </button>
        <button
          [disabled]="currentPage.disabled"
          color="primary"
          mat-raised-button
          class="m-l-12 save-button"
          (click)="save(currentPage)"
          type="button">
          <mat-spinner
            diameter="24"
            *ngIf="currentPage.loading"></mat-spinner>
          <span *ngIf="!currentPage.loading">{{ 'CONTACT_PREFERENCES.SAVE' | translate }}</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
