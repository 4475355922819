<div class="container">
  <div class="header">
    <h1>{{ 'MUSIC_ON_HOLD_PREFERENCES.MANAGE_MUSIC' | translate }}</h1>
  </div>
  <div class="body">
    <mat-form-field
      appearance="outline"
      class="full form-no-error density-minus-3">
      <mat-label>{{ 'MUSIC_ON_HOLD_PREFERENCES.FILE_NAME_LABEL' | translate }}</mat-label>
      <input
        [(ngModel)]="fileName"
        autocomplete="off"
        class="search-call-history"
        matInput
        disabled />
    </mat-form-field>
    <div class="download-btn">
      <button
        class="btn"
        color="primary"
        (click)="openFileSelector()">
        {{ 'MUSIC_ON_HOLD_PREFERENCES.SELECT_DIFFERENT_FILE' | translate }}
        <mat-icon color="primary">upload</mat-icon>
      </button>
      <input
        type="file"
        #fileInput
        accept="audio/wav, audio/mp3"
        (change)="addFile($event)"
        [style.display]="'none'" />
    </div>
    <mat-form-field
      appearance="outline"
      class="full form-no-error density-minus-3">
      <mat-label>{{ 'MUSIC_ON_HOLD_PREFERENCES.SONG_NAME_OR_DESCRIPTION_LABEL' | translate }}</mat-label>
      <input
        [(ngModel)]="songDescription"
        autocomplete="off"
        class="search-call-history"
        matInput />
    </mat-form-field>
  </div>

  <div class="button-container">
    <mat-spinner
      class="inline-spinner"
      [diameter]="20"
      *ngIf="loadingFiles"></mat-spinner>
    <button
      class="btn btn-cancel"
      (click)="close()"
      type="button">
      {{ 'MUSIC_ON_HOLD_PREFERENCES.CANCEL_BUTTON' | translate }}
    </button>
    <button
      (click)="onSubmit()"
      class="btn-blue"
      color="primary"
      type="submit">
      {{ 'MUSIC_ON_HOLD_PREFERENCES.SAVE_BUTTON' | translate }}
    </button>
  </div>
</div>
