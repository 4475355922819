import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactType } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { SnackBarType } from '@app/core/models/snack-bar-data.model';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AppTranslateService } from '@app/core/services/app-translate.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-import-contacts',
  templateUrl: './import-contacts.component.html',
  styleUrls: ['./import-contacts.component.scss'],
})
export class ImportContactsComponent {
  contactFile: File = new File([], '');
  importContactFormGroup: FormGroup;
  allowedFiles: string[] = ['.csv'];
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  loading: boolean = false;
  protected readonly ContactType = ContactType;

  constructor(
    private dialogRef: MatDialogRef<ImportContactsComponent>,
    private fb: FormBuilder,
    protected appConfigService: AppConfigService,
    private contactService: ContactService,
    private snackbar: SnackbarService,
    private translate: AppTranslateService
  ) {
    this.importContactFormGroup = this.fb.group({
      file: [null, Validators.required],
      type: [ContactType.Personal, Validators.required],
      merge: [1, Validators.required],
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  openFileSelector(): void {
    this.fileInput.nativeElement.click();
  }

  removeFaxFile(): void {
    this.contactFile = new File([], '');
    this.importContactFormGroup.controls['file'].reset();
  }

  addFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      this.contactFile = target.files[0];
      this.importContactFormGroup.controls['file'].patchValue(this.contactFile);
    }
    target.value = '';
  }

  async submit() {
    this.loading = true;
    if (this.contactFile.type !== 'text/csv') {
      this.snackbar.open(this.translate.instant('CONTACT_PREFERENCES.IF_EXPORT_DESC'), '', {
        duration: 5000,
        panelClass: SnackBarType.ERROR,
      });
      this.loading = false;
      return;
    }
    const formData = new FormData();
    formData.append('file', this.contactFile);
    formData.append('type', this.importContactFormGroup.value.type);
    formData.append('source', 'ucws');
    formData.append('merge', this.importContactFormGroup.value.merge);
    try {
      const response = await firstValueFrom(this.contactService.importContacts(formData));
      if (response) {
        this.snackbar.open(
          this.translate.instant('CONTACT_PREFERENCES.CONTACTS_IMPORT_SUCCESSFUL'),
          this.translate.instant('CONTACT_PREFERENCES.OK')
        );
        this.loading = false;
        this.dialogRef.close();
      }
    } catch {
      this.snackbar.open(this.translate.instant('CONTACT_PREFERENCES.CONTACTS_IMPORT_NOT_SUCCESSFUL'), '', {
        duration: 5000,
        panelClass: SnackBarType.ERROR,
      });
      this.loading = false;
    }
  }
}
