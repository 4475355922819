<div class="name-container">
  <div class="name-flex">
    <app-integration-favicon [integration]="integration"></app-integration-favicon>
    <span class="display-name">{{ integration.display_name }}</span>
  </div>
  <label
    class="toggle-label"
    [for]="'enableToggle' + integration.id">
    <div class="toggle-container">
      <input
        class="toggle-input"
        type="checkbox"
        [id]="'enableToggle' + integration.id"
        [checked]="integration.enabled"
        (change)="integrationEnableChanged($event)" />
      <div class="toggle-box"></div>
      <div class="toggle-dot"></div>
    </div>
  </label>
</div>

<div class="settings-container">
  <button
    class="settings-button"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [disabled]="!integration.enabled"
    (click)="isOverlayOpen = !isOverlayOpen">
    {{ 'INTEGRATIONS_PREFERENCES.AUTO_POP_SETTINGS' | translate }}
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ]"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  (overlayOutsideClick)="isOverlayOpen = false">
  <div class="overlay-content">
    <label class="overlay-label">
      {{ 'INTEGRATIONS_PREFERENCES.AUTO_POP_INCOMING' | translate }}
      <input
        class="overlay-checkbox"
        type="checkbox"
        [checked]="integration.auto_pop"
        (change)="integrationAutoPopChanged($event)" />
    </label>
    <label class="overlay-label">
      {{ 'INTEGRATIONS_PREFERENCES.AUTO_POP_OUTBOUND' | translate }}
      <input
        class="overlay-checkbox"
        type="checkbox"
        [checked]="integration.auto_dial"
        (change)="integrationAutoDialChanged($event)" />
    </label>
  </div>
</ng-template>
