import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true,
})
export class InitialsPipe implements PipeTransform {
  transform(value?: string | null | undefined): string {
    if (!value) {
      return '-'; // Return an empty string if name is null, undefined, or empty
    }

    // Split the name by spaces to separate each word
    const words = value.trim().split(/\s+/);

    // Map each word to its first letter and convert to uppercase
    const initials = words.map((word) => word[0].toUpperCase());

    // Join the initials together and return
    return initials.join('');
  }
}
