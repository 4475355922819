<div>
  <h1>{{ 'INTEGRATIONS_PREFERENCES.WEBPOP' | translate }}</h1>
  <h4>{{ 'INTEGRATIONS_PREFERENCES.BROWSER_INTEGRATION' | translate }}</h4>
  <mat-divider></mat-divider>
  <p>{{ 'INTEGRATIONS_PREFERENCES.BROWSER_INTEGRATION_DESCRIPTION' | translate }}</p>

  <div class="btn-group">
    <!--    (click)="save(currentPage)"-->
    @let isPwaInstalled = pwaService.pwaInstalled$ | async;
    <button
      color="primary"
      mat-raised-button
      class="m-l-12"
      type="button"
      [disabled]="isPwaInstalled"
      (click)="installConnectUCPwa()"
      [matTooltip]="'INTEGRATIONS_PREFERENCES.INSTALL_BUTTON_TOOLTIP' | translate">
      <mat-icon style="transform: rotate(180deg)"> publish</mat-icon>
      <span>{{ 'INTEGRATIONS_PREFERENCES.INSTALL_BUTTON' | translate }}</span>
    </button>
    <button
      color="primary"
      mat-stroked-button
      class="m-l-12"
      type="button"
      [disabled]="!isPwaInstalled || protocolHandlerService.isRegistered('tel')"
      (click)="registerTelHandler()"
      [matTooltip]="'INTEGRATIONS_PREFERENCES.REGISTER_FOR_ONE_CLICK_TOOLTIP' | translate">
      <span>{{ 'INTEGRATIONS_PREFERENCES.REGISTER_FOR_ONE_CLICK' | translate }}</span>
    </button>
  </div>
</div>

<!-- 
    If embedded in an electron app, we know it has been installed and we 
    don't need to show the download links. Instead, just 
   -->

@if (!isRunningInElectron) {
  <div class="web-pop-section">
    <h4>{{ 'INTEGRATIONS_PREFERENCES.CONNECTUC_LINK' | translate }}</h4>
    <mat-divider></mat-divider>
    <p>
      {{ 'INTEGRATIONS_PREFERENCES.CONNECTUC_LINK_DESCRIPTION' | translate }}
    </p>
    <div class="btn-group">
      <button
        class="connect-button"
        [disabled]="!AppStoreUrls.macAppStore"
        [matTooltip]="'INTEGRATIONS_PREFERENCES.INSTALL_FOR_MAC_TOOLTIP' | translate"
        (click)="handleAppStoreUrlClick(AppStoreUrls.macAppStore)">
        <img
          src="assets/macIcon.png"
          alt="{{ 'INTEGRATIONS_PREFERENCES.MAC' | translate }}" />
        {{ 'INTEGRATIONS_PREFERENCES.INSTALL_FOR_MAC' | translate }}
      </button>
      <button
        class="connect-button"
        [disabled]="!AppStoreUrls.microsoftStore"
        [matTooltip]="'INTEGRATIONS_PREFERENCES.INSTALL_FOR_WINDOWS_TOOLTIP' | translate"
        (click)="handleAppStoreUrlClick(AppStoreUrls.microsoftStore)">
        <img
          alt="{{ 'INTEGRATIONS_PREFERENCES.WINDOWS' | translate }}"
          src="assets/windowsIcon.png" />
        {{ 'INTEGRATIONS_PREFERENCES.INSTALL_FOR_WINDOWS' | translate }}
      </button>
    </div>
  </div>
}

@if (isRunningInElectron && appConfigService.features[AppFeature.WebPopIntegration]) {
  <div>
    <h4>{{ 'INTEGRATIONS_PREFERENCES.AVAILABLE_WEBPOP_INTEGRATIONS' | translate }}</h4>
    <mat-divider></mat-divider>
    <p>{{ 'INTEGRATIONS_PREFERENCES.AVAILABLE_WEBPOP_INTEGRATIONS_DESCRIPTION' | translate }}</p>

    @let isLoading = integrationsService.isLoading$ | async;
    @if (isLoading) {
      <mat-spinner [diameter]="20"></mat-spinner>
    } @else {
      <div class="settings-item-container">
        @let integrations = integrationsService.integrations$ | async;
        @if (integrations && integrations.length > 0) {
          @for (integration of integrations; track integration.id) {
            <app-integration-settings-item
              [integration]="integration"
              (integrationChanged)="handleIntegrationUpdated($event)">
            </app-integration-settings-item>
            <mat-divider />
          }

          <div>
            <h4 class="advanced-configuration-logs-button">
              {{ 'INTEGRATIONS_PREFERENCES.ADVANCED_CONFIGURATION_LOGS' | translate }}
            </h4>

            @let logs = (lambdaService.logs$ | async) || [];
            @if (logs.length > 0) {
              <mat-accordion [displayMode]="'flat'">
                @for (log of logs; track log.createdAt) {
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title> {{ log.integrationTitle }} </mat-panel-title>
                      <mat-panel-description> {{ log.createdAt | date: 'mediumDate' }} </mat-panel-description>
                    </mat-expansion-panel-header>
                    <app-lambda-output-details
                      [lambdaOutput]="log"
                      (downloadFileBtnClicked)="handleDownloadLogClick(log)" />
                  </mat-expansion-panel>
                }
              </mat-accordion>
            } @else {
              <div class="no-logs">
                {{ 'INTEGRATIONS_PREFERENCES.NO_LOGS' | translate }}
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}
