import { animate, AUTO_STYLE, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

interface BannerAction {
  text: string;
  tooltip: string;
}

@Component({
  standalone: true,
  imports: [MatIconModule, CommonModule, MatTooltipModule],
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('expandCollapse', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('300ms 0s ease-in', style({ opacity: 1, height: AUTO_STYLE })),
      ]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0, height: 0 }))]),
    ]),
  ],
})
export class BannerComponent {
  @Input() type: 'error' | 'info' | 'success' | 'warning' | 'notice' = 'info';
  @Input() open: boolean;
  @Input() icon: string;
  @Input() customIcon: string;
  @Input() text: string;
  @Input() actions: Array<string | BannerAction> = [];
  @Input() closeTooltip: string = 'close';
  @Output() actionClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeClick: EventEmitter<void> = new EventEmitter<void>();

  get actionsTransformed(): BannerAction[] {
    return this.actions.map((action) => {
      if (typeof action === 'string') {
        return {
          text: action,
          tooltip: '',
        };
      }
      return action;
    });
  }

  handleActionClick(action: BannerAction) {
    this.actionClick.emit(action.text);
  }
}
