<div
  class="import-contact-container"
  [formGroup]="importContactFormGroup">
  <mat-icon
    class="close-btn"
    (click)="close()"
    >close
  </mat-icon>
  <h1 class="header">{{ 'CONTACT_PREFERENCES.IMPORT_CONTACTS' | translate }}</h1>
  <div class="files">
    <div>
      <mat-form-field
        class="file input-40"
        appearance="outline"
        floatLabel="always">
        <mat-label>{{ 'CONTACT_PREFERENCES.FILE_NAME' | translate }}</mat-label>
        <input
          autocomplete="off"
          readonly="true"
          matInput
          value="{{ contactFile.name }}"
          placeholder=""
          (click)="openFileSelector()" />
      </mat-form-field>
      <span
        (click)="removeFaxFile()"
        class="remove">
        <mat-icon>delete_outline</mat-icon>
      </span>
    </div>
  </div>
  <div class="add-file-wrapper">
    <div
      class="add-file"
      (click)="openFileSelector()">
      {{ 'CONTACT_PREFERENCES.SELECT_A_DIFFERENT_FILE' | translate }}
      <mat-icon>file_upload_outline</mat-icon>
    </div>
  </div>
  <input
    type="file"
    #fileInput
    accept="{{ allowedFiles.join(',') }}"
    (change)="addFile($event)"
    [style.display]="'none'" />
  <mat-form-field
    class="file input-40 m-t-30"
    appearance="outline">
    <mat-label>{{ 'CONTACT_PREFERENCES.ADD_TO_CONTACT_LIST' | translate }}</mat-label>
    <div class="flex">
      <mat-select formControlName="type">
        <mat-option
          *ngIf="appConfigService.hasOfficeManagerRole$ | async"
          [value]="ContactType.Shared"
          >{{ ContactType.Shared | contactTypeLabel }}</mat-option
        >
        <mat-option [value]="ContactType.Personal">{{ ContactType.Personal | contactTypeLabel }}</mat-option>
      </mat-select>
    </div>
  </mat-form-field>
  <mat-form-field
    class="file input-40 m-t-30"
    appearance="outline">
    <mat-label>{{ 'CONTACT_PREFERENCES.CONFLICT_RESOLUTION' | translate }}</mat-label>
    <div class="flex">
      <mat-select formControlName="merge">
        <mat-option [value]="1">{{ 'CONTACT_PREFERENCES.MERGE' | translate }}</mat-option>
        <mat-option [value]="0">{{ 'CONTACT_PREFERENCES.DUPLICATE' | translate }}</mat-option>
      </mat-select>
    </div>
  </mat-form-field>
  <div class="button-container">
    <mat-divider></mat-divider>
    <button
      class="btn btn-cancel"
      (click)="close()"
      type="button">
      {{ 'CONTACT_PREFERENCES.CANCEL' | translate }}
    </button>
    <button
      (click)="submit()"
      class="btn-blue"
      [ngClass]="{ loading: loading }"
      color="primary"
      type="submit">
      <mat-spinner
        [diameter]="20"
        *ngIf="loading"></mat-spinner>
      <span *ngIf="!loading">{{ 'CONTACT_PREFERENCES.SAVE' | translate }}</span>
    </button>
  </div>
</div>
