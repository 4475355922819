import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { ApiChangePassword } from '../../models/profile';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
  ],
})
export class ChangePasswordComponent {
  protected type = 'password';
  protected passwordForm: FormGroup;
  @Output() passwordChange = new EventEmitter<ApiChangePassword>();
  @Output() cancelChanges = new EventEmitter<void>();

  /**
   *
   * @param fb
   */
  constructor(private fb: FormBuilder) {
    this.passwordForm = this.fb.group(
      {
        currentPassword: ['', [Validators.required]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            this.patternValidator(/\d/, { hasNumber: true }),
            this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            this.patternValidator(/[a-z]/, { hasSmallCase: true }),
          ],
        ],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      {
        validators: [this.match('newPassword', 'confirmPassword')],
      }
    );
  }

  /**
   *
   */
  get field(): { [key: string]: AbstractControl } {
    return this.passwordForm.controls;
  }

  protected changePassword(): void {
    if (this.passwordForm.valid) {
      this.passwordChange.emit(this.passwordForm.value);
    }
  }

  protected close() {
    this.cancelChanges.emit();
  }

  protected peaky(): void {
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  /**
   *
   * @param controlName
   * @param checkControlName
   */
  private match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl?.errors && !checkControl.errors['matching']) {
        return null;
      }
      if (control?.value === checkControl?.value) {
        return null;
      } else {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      }
    };
  }

  /**
   *
   * @param regex
   * @param error
   */
  private patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl) => {
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
}
