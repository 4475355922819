import { DatePipe, KeyValuePipe, NgTemplateOutlet } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { LambdaLogDBModel } from '@app/preferences/models/lambda.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-lambda-output-details',
  imports: [KeyValuePipe, DatePipe, NgTemplateOutlet, MatIcon, TranslateModule],
  templateUrl: './lambda-output-details.component.html',
  styleUrls: ['./lambda-output-details.component.scss'],
})
export class LambdaOutputDetailsComponent {
  lambdaOutput = input.required<LambdaLogDBModel>();
  downloadFileBtnClicked = output<LambdaLogDBModel>();
}
