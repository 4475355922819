import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AppTranslateService } from '@app/core/services/app-translate.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { ButtonType } from '@app/shared/models/dialog-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, firstValueFrom, map, startWith, Subject, takeUntil } from 'rxjs';

import {
  AnsweringRule,
  AnsweringRuleType,
  ForwardDestination,
  SimRingDestination,
  TimeFrame,
} from '../../../models/answering-rules.models';
import { AnsweringRulesService } from '../../../services/answering-rules.service';
import { AddTimeFrameComponent } from '../add-time-frame/add-time-frame.component';

@UntilDestroy()
@Component({
  selector: 'app-add-answering-rule',
  templateUrl: './add-answering-rule.component.html',
  styleUrls: ['./add-answering-rule.component.scss'],
})
export class AddAnsweringRuleComponent implements OnInit, AfterViewInit {
  @Input() answeringRule: AnsweringRule | undefined;
  @Input() timeFramesUsedByMe: string[] = [];

  @Output() saveRule = new EventEmitter<void>();
  @Output() cancelChanges = new EventEmitter<void>();

  @ViewChild('container') container: ElementRef<HTMLDivElement>;
  @ViewChild('greyMask') greyMask: ElementRef<HTMLDivElement>;

  @ViewChild('timeFrame') timeFrame: AddTimeFrameComponent;
  @ViewChild('simRingDestination') simRingDestination: ElementRef<HTMLInputElement>;
  @ViewChild('simRingDelay') simRingDelay: ElementRef<HTMLInputElement>;

  protected readonly AutocompleteSource = AutocompleteSource;
  protected readonly AnsweringRuleType = AnsweringRuleType;
  protected readonly AppFeature = AppFeature;
  protected readonly Number = Number;

  protected saving = false;

  protected filteredTimeFrames: TimeFrame[] = [];
  protected filterCtrl: FormControl;

  private allDestinations: ForwardDestination[];
  protected filteredDestinations: ForwardDestination[];
  private autocompleteChange$: Subject<void> = new Subject<void>();

  protected newTimeFrameOptionString = '!_!_newTimeFrame_!_!';

  protected ruleForm!: FormGroup;
  protected pageTitle: string;
  protected editSimRingDestIndex: number = -1;

  protected defaultRule = false;
  protected invalidTimeFrame = false;

  protected editingDisabled = true;

  protected get otherDestinations() {
    return this.ruleForm.get('simRingOtherDestinations') as FormArray;
  }
  protected get alwaysDestinationCtrl() {
    return this.ruleForm.get('forwardAlwaysDestination') as FormControl;
  }
  protected get ruleType() {
    return this.ruleForm.get('ruleType') as FormControl;
  }

  private callForwardCheckboxes = [
    'forwardOnActiveEnabled',
    'forwardBusyEnabled',
    'forwardNoAnswerEnabled',
    'forwardOfflineEnabled',
  ];
  private callForwardInputs = [
    'forwardOnActiveDestination',
    'forwardBusyDestination',
    'forwardNoAnswerDestination',
    'forwardOfflineDestination',
  ];

  constructor(
    private fb: FormBuilder,
    protected answeringRulesService: AnsweringRulesService,
    protected appConfigService: AppConfigService,
    private cdr: ChangeDetectorRef,
    private snackBar: SnackbarService,
    private dialog: MatDialog,
    private appTranslateService: AppTranslateService
  ) {
    this.ruleForm = this.fb.group({
      ruleType: ['', Validators.required],
      enabled: true,
      timeFrame: ['', Validators.required],
      doNotDisturb: false,
      callScreening: false,
      forwardAlwaysEnabled: false,
      forwardAlwaysDestination: '',
      forwardOnActiveEnabled: false,
      forwardOnActiveDestination: '',
      forwardBusyEnabled: false,
      forwardBusyDestination: '',
      forwardNoAnswerEnabled: false,
      forwardNoAnswerDestination: '',
      forwardOfflineEnabled: false,
      forwardOfflineDestination: '',
      simRingEnabled: false,
      simRingUsersExtension: false,
      simRingAllDevices: false,
      simRingConfirmOffnet: false,
      simRingOtherDestinations: this.fb.array([]),
    });

    this.filterCtrl = this.fb.control(false || !appConfigService.features[AppFeature.ModifyTimeFrames]);

    this.answeringRulesService.timeFrames$.pipe(untilDestroyed(this)).subscribe(() => {
      this.filterTimeFrames();
    });

    this.answeringRulesService.forwardDestinations$.pipe(untilDestroyed(this)).subscribe((destinations) => {
      this.allDestinations = destinations;
    });
  }

  ngOnInit() {
    this.editingDisabled = !this.appConfigService.features[AppFeature.ModifyAnsweringRules];

    this.setControlsState(this.callForwardInputs, false);

    if (this.answeringRule) {
      this.pageTitle = 'Edit an Answering Rule';

      if (this.answeringRule.timeFrame === '*') {
        this.defaultRule = true;
      }

      const ruleType = this.answeringRulesService.answeringRuleType(this.answeringRule);
      this.ruleType.patchValue(ruleType);
      this.setRuleType(ruleType, false);

      this.ruleForm.patchValue({
        enabled: this.answeringRule.enabled,
        timeFrame: this.answeringRule.timeFrame,
        doNotDisturb: this.answeringRule.doNotDisturb,
        callScreening: this.answeringRule.callScreening,
        forwardAlwaysEnabled: this.answeringRule.forwardAlways.enabled,
        forwardAlwaysDestination: this.answeringRule.forwardAlways.destination,
        forwardOnActiveEnabled: this.answeringRule.forwardOnActive.enabled,
        forwardOnActiveDestination: this.answeringRule.forwardOnActive.destination,
        forwardBusyEnabled: this.answeringRule.forwardBusy.enabled,
        forwardBusyDestination: this.answeringRule.forwardBusy.destination,
        forwardNoAnswerEnabled: this.answeringRule.forwardNoAnswer.enabled,
        forwardNoAnswerDestination: this.answeringRule.forwardNoAnswer.destination,
        forwardOfflineEnabled: this.answeringRule.forwardOffline.enabled,
        forwardOfflineDestination: this.answeringRule.forwardOffline.destination,
        simRingEnabled: this.answeringRule.simRing.enabled,
        simRingUsersExtension: this.answeringRule.simRing.usersExtension,
        simRingAllDevices: this.answeringRule.simRing.allDevices,
        simRingConfirmOffnet: this.answeringRule.simRing.confirmOffnet,
      });

      for (const dest of this.answeringRule.simRing.otherDestinations) {
        this.addDestination(dest);
      }

      this.ruleForm.get('timeFrame')?.disable();

      this.enableCallForwardInputs();
    } else {
      this.pageTitle = 'Add an Answering Rule';
    }
  }

  ngAfterViewInit(): void {
    if (this.editingDisabled) {
      // pass scroll events from grey mask to main container
      this.greyMask?.nativeElement.addEventListener('wheel', (event: WheelEvent) => {
        this.container.nativeElement.scrollBy(event.deltaX, event.deltaY);
      });

      // block mouse and keyboard events
      this.container.nativeElement.addEventListener('click', this.stopAndPreventDefault, true);
      this.container.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key !== 'Escape') {
          this.stopAndPreventDefault(event);
        }
      });
    }
  }

  private stopAndPreventDefault(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  protected async saveEvent() {
    if (this.editingDisabled) {
      return;
    }

    const timeFrame = this.ruleForm.get('timeFrame')?.value;
    const rule = this.getRuleData();

    if (timeFrame === this.newTimeFrameOptionString) {
      const timeFrameData = this.timeFrame.getTimeFrameData();
      this.saving = true;
      try {
        await firstValueFrom(
          this.answeringRulesService.addTimeFrame(timeFrameData.time_frame, timeFrameData.time_range_data, true)
        );
        this.createRule(timeFrameData.time_frame, rule);
      } catch (error) {
        console.error('Create new time frame error:', error);
        const message: string = error.error?.message ?? '';
        this.snackBar.open(
          message.includes('time frame already exists')
            ? message
            : this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.CREATE_TIME_FRAME_FAILED'),
          this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.OK')
        );
      } finally {
        this.saving = false;
      }
      return;
    }

    if (this.answeringRule) {
      this.updateRule(timeFrame, rule);
    } else {
      this.createRule(timeFrame, rule);
    }
  }

  protected cancelEvent(): void {
    this.cancelChanges.emit();
  }

  protected behaviourSelectionChange(event: MatSelectChange): void {
    this.setRuleType(event.value, true);
  }

  protected async deleteTimeFrame(event: Event, timeFrame: string) {
    event.stopPropagation();
    if (!this.timeFramesUsedByMe.includes(timeFrame)) {
      this.confirmTimeFrameDelete(timeFrame);
    }
  }

  protected editSimRingDestination(index: number): void {
    this.editSimRingDestIndex = index;
  }

  protected deleteSimRingDestination(index: number): void {
    this.editSimRingDestIndex = -1;
    this.otherDestinations.removeAt(index);
  }

  protected editSimRingDestinationDone(): void {
    this.editSimRingDestIndex = -1;
  }

  protected addSimRingDestination(): void {
    this.addDestination({
      destination: this.simRingDestination.nativeElement.value,
      delay: Number(this.simRingDelay.nativeElement.value),
    });
    this.simRingDestination.nativeElement.value = '';
    this.simRingDelay.nativeElement.value = '0';
    this.ruleForm.markAsDirty();
  }

  protected onInputFieldFocus(source: AutocompleteSource): void {
    this.autocompleteChange$.next();

    let ctrl: AbstractControl | null;
    switch (source) {
      case AutocompleteSource.Always: {
        ctrl = this.alwaysDestinationCtrl;
        break;
      }
      case AutocompleteSource.OnActive: {
        ctrl = this.ruleForm.get('forwardOnActiveDestination');
        break;
      }
      case AutocompleteSource.Busy: {
        ctrl = this.ruleForm.get('forwardBusyDestination');
        break;
      }
      case AutocompleteSource.NoAnswer: {
        ctrl = this.ruleForm.get('forwardNoAnswerDestination');
        break;
      }
      case AutocompleteSource.Offline: {
        ctrl = this.ruleForm.get('forwardOfflineDestination');
        break;
      }
    }

    if (ctrl) {
      ctrl.valueChanges
        .pipe(
          untilDestroyed(this),
          takeUntil(this.autocompleteChange$),
          filter((value) => typeof value === 'string'),
          startWith(ctrl.value),
          map((value) => this.filter(value || ''))
        )
        .subscribe((destinations) => {
          this.filteredDestinations = destinations;
        });
    }
  }

  protected trackByValue(index: number, destination: ForwardDestination) {
    return destination.value;
  }

  protected close(): void {
    this.cancelChanges.emit();
  }

  protected filterTimeFrames(): void {
    this.filteredTimeFrames = this.answeringRulesService.filterTimeFrames(this.filterCtrl.value);
  }

  private getRuleData(): AnsweringRule {
    const formData = this.ruleForm.getRawValue();
    return {
      enabled: formData.enabled,
      doNotDisturb: formData.doNotDisturb,
      callScreening: formData.callScreening,
      forwardAlways: {
        enabled: formData.forwardAlwaysEnabled,
        destination: this.answeringRulesService.fwdDestDescriptionToValueString(formData.forwardAlwaysDestination),
      },
      forwardOnActive: {
        enabled: formData.forwardOnActiveEnabled,
        destination: this.answeringRulesService.fwdDestDescriptionToValueString(formData.forwardOnActiveDestination),
      },
      forwardBusy: {
        enabled: formData.forwardBusyEnabled,
        destination: this.answeringRulesService.fwdDestDescriptionToValueString(formData.forwardBusyDestination),
      },
      forwardNoAnswer: {
        enabled: formData.forwardNoAnswerEnabled,
        destination: this.answeringRulesService.fwdDestDescriptionToValueString(formData.forwardNoAnswerDestination),
      },
      forwardOffline: {
        enabled: formData.forwardOfflineEnabled,
        destination: this.answeringRulesService.fwdDestDescriptionToValueString(formData.forwardOfflineDestination),
      },
      simRing: {
        enabled: formData.simRingEnabled,
        usersExtension: formData.simRingUsersExtension,
        allDevices: formData.simRingAllDevices,
        confirmOffnet: formData.simRingConfirmOffnet,
        otherDestinations: formData.simRingOtherDestinations,
      },
    };
  }

  private async createRule(timeFrame: string, rule: AnsweringRule) {
    this.saving = true;
    try {
      await firstValueFrom(this.answeringRulesService.createRule(timeFrame, rule));
      this.close(); // return to main panel on success
    } catch (error) {
      console.error('Create rule error:', error);
      this.snackBar.open(
        this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.SAVING_RULE_FAILED'),
        this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.OK')
      );
    } finally {
      this.saving = false;
    }
  }

  private async updateRule(timeFrame: string, rule: AnsweringRule) {
    this.saving = true;
    try {
      await firstValueFrom(this.answeringRulesService.updateRule(timeFrame, rule));
      this.close(); // return to main panel on success
    } catch (error) {
      console.error('Update rule error:', error);
      this.snackBar.open(
        this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.SAVING_RULE_FAILED'),
        this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.OK')
      );
    } finally {
      this.saving = false;
    }
  }

  private confirmTimeFrameDelete(timeFrame: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        description: this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.ARE_YOU_WANT_DELETE_TIME_FRAME', {
          timeFrame,
        }),
        buttons: [
          {
            label: this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.CANCEL'),
            action: () => null,
            type: ButtonType.matFlatButton,
          },
          {
            label: this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.DELETE_TIME_FRAME'),
            action: async () => await this.deleteTimeFrameSnack(timeFrame),
            type: ButtonType.matRaisedButton,
            default: true,
            color: 'primary',
          },
        ],
      },
    });
  }

  private async deleteTimeFrameSnack(timeFrame: string) {
    try {
      await firstValueFrom(this.answeringRulesService.deleteTimeFrame(timeFrame));
    } catch (error) {
      console.error('Delete time frame error:', error);
      this.snackBar.open(
        this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.DELETING_TIME_FRAME_FAILED'),
        this.appTranslateService.instant('ANSWERING_RULES_PREFERENCES.OK')
      );
    }
  }

  private setRuleType(type: AnsweringRuleType, markDirty: boolean): void {
    this.cdr.detectChanges();
    if (markDirty) {
      // rule type was changed by user
      this.ruleForm.markAsDirty();
    }
    this.editSimRingDestinationDone();
    switch (type) {
      case AnsweringRuleType.DoNotDisturb: {
        this.setDNDMode();
        break;
      }
      case AnsweringRuleType.ForwardAllCalls: {
        this.setForwardAllMode();
        break;
      }
      case AnsweringRuleType.OnlyPrimaryDeskphone: {
        this.setOnlyPrimaryPhoneMode();
        break;
      }
      case AnsweringRuleType.SimultaneousRing: {
        this.setSimRingMode();
      }
    }
  }

  private baseFormState(): void {
    this.ruleForm.patchValue({
      doNotDisturb: false,
      forwardAlwaysEnabled: false,
      forwardAlwaysDestination: '',
      forwardOnActiveEnabled: false,
      forwardOnActiveDestination: '',
      forwardBusyEnabled: false,
      forwardBusyDestination: '',
      forwardNoAnswerEnabled: false,
      forwardNoAnswerDestination: '',
      forwardOfflineEnabled: false,
      forwardOfflineDestination: '',
      simRingEnabled: false,
      simRingUsersExtension: false,
      simRingAllDevices: false,
    });

    for (const destInput of this.callForwardInputs) {
      this.ruleForm.get(destInput)?.markAsUntouched();
    }

    this.setCallForwardInputsState(this.callForwardInputs, false);
    this.alwaysDestinationCtrl.clearValidators();
    this.alwaysDestinationCtrl.disable();
    this.alwaysDestinationCtrl.markAsUntouched();
    this.otherDestinations.clear();
    this.ruleForm.updateValueAndValidity();
  }

  private setDNDMode(): void {
    this.baseFormState();
    this.ruleForm.patchValue({
      doNotDisturb: true,
      callScreening: false,
      simRingEnabled: false,
    });
    this.ruleForm.get('callScreening')?.disable();
    this.ruleForm.updateValueAndValidity();
  }

  private setForwardAllMode(): void {
    this.baseFormState();
    this.ruleForm.patchValue({
      doNotDisturb: false,
      forwardAlwaysEnabled: true,
      simRingEnabled: false,
    });
    this.ruleForm.get('callScreening')?.enable();
    this.alwaysDestinationCtrl.enable();
    this.alwaysDestinationCtrl.addValidators([Validators.required, Validators.minLength(1)]);
    this.alwaysDestinationCtrl.updateValueAndValidity();
    this.ruleForm.updateValueAndValidity();
  }

  private setSimRingMode(): void {
    this.baseFormState();
    this.ruleForm.patchValue({
      doNotDisturb: false,
      forwardAlwaysEnabled: false,
      simRingEnabled: true,
      simRingUsersExtension: true,
    });
    this.ruleForm.get('callScreening')?.enable();
    this.ruleForm.updateValueAndValidity();
  }

  private setOnlyPrimaryPhoneMode(): void {
    this.baseFormState();
    this.ruleForm.patchValue({
      doNotDisturb: false,
      forwardAlwaysEnabled: false,
      simRingEnabled: false,
    });
    this.ruleForm.get('callScreening')?.enable();
    this.ruleForm.updateValueAndValidity();
  }

  private addDestination(dest: SimRingDestination): void {
    this.otherDestinations.push(
      this.fb.group({
        destination: [dest.destination, [Validators.required, Validators.minLength(1)]],
        delay: [dest.delay, [Validators.required, Validators.min(0), Validators.max(60)]],
      })
    );
  }

  private filter(value: string): ForwardDestination[] {
    const searchVal = value.toLowerCase();
    const filtered =
      value.length > 1
        ? this.allDestinations.filter((destination) => {
            return destination.description.toLowerCase().includes(searchVal);
          })
        : [];
    return filtered;
  }

  private enableCallForwardInputs(): void {
    // according to respective checkbox
    for (let i = 0; i < this.callForwardCheckboxes.length; i++) {
      if (this.ruleForm.get(this.callForwardCheckboxes[i])?.value) {
        const ctrl = this.ruleForm.get(this.callForwardInputs[i]);
        ctrl?.enable();
        ctrl?.addValidators([Validators.required, Validators.minLength(1)]);
        ctrl?.updateValueAndValidity();
      }
    }
  }

  private setControlsState(ctrls: string[], state: boolean): void {
    for (const ctrl of ctrls) {
      state ? this.ruleForm.get(ctrl)?.enable() : this.ruleForm.get(ctrl)?.disable();
    }
  }

  // set call forward destination control state according to its checkbox state
  protected setCallForwardInputsState(ctrlNames: string[], state: boolean): void {
    for (const ctrlName of ctrlNames) {
      const checkboxCtrl = this.ruleForm.get(ctrlName.replace('Destination', '') + 'Enabled');
      const inputCtrl = this.ruleForm.get(ctrlName);
      state && checkboxCtrl?.value ? inputCtrl?.enable() : inputCtrl?.disable();
      state && checkboxCtrl?.value
        ? inputCtrl?.addValidators([Validators.required, Validators.minLength(1)])
        : inputCtrl?.clearValidators();
      inputCtrl?.updateValueAndValidity();
    }
  }
}

enum AutocompleteSource {
  Always = 'always',
  OnActive = 'onActive',
  Busy = 'busy',
  NoAnswer = 'noAnswer',
  Offline = 'offline',
}
