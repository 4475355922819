import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AppTranslateService } from '@app/core/services/app-translate.service';
import { ChimeMeetingService } from '@app/meetings/services/chime-meeting.service';
import { ConsoleLogger, DefaultDeviceController, Device, LogLevel } from 'amazon-chime-sdk-js';
import { finalize, firstValueFrom } from 'rxjs';

import { VideoSettings } from '../../models/settings.models';
import { SettingsPage } from '../../models/settings-page';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent extends SettingsPage implements OnInit, OnDestroy {
  @ViewChild('video', { static: true })
  private webcam: ElementRef;
  private currentMediaStream: MediaStream;
  private componentDestroyed = false;
  activeCameraList: MediaDeviceInfo[] = [];
  selectedCamera?: MediaDeviceInfo;
  form: FormGroup;
  private deviceController: DefaultDeviceController;

  get mirrorLocalVideo(): boolean {
    return this.form.controls['mirrorLocalVideo'].value;
  }

  get cameraDevice(): string {
    return this.form.controls['cameraDevice'].value;
  }

  set cameraDevice(deviceId: string) {
    this.form.controls['cameraDevice'].setValue(deviceId);
  }

  get blurBackground(): boolean {
    return this.form.controls['blurBackground'].value;
  }

  set blurBackground(blurBackground: boolean) {
    this.form.controls['blurBackground'].setValue(blurBackground);
  }

  constructor(
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private chimeMeetingService: ChimeMeetingService,
    private appConfigService: AppConfigService,
    private appTranslateService: AppTranslateService
  ) {
    super();
    this.form = this.createFormGroup(this.settingsService.videoSettings);
  }

  public ngOnInit(): void {
    this.settingsService.videoSettings$.subscribe(async (settings: VideoSettings) => {
      this.form = this.createFormGroup(settings);
      await this.scanDevices();
      await this.tryStartCameraStream();
    });
  }

  private createFormGroup(settings: VideoSettings): FormGroup {
    return this.formBuilder.group({
      cameraDevice: [settings.cameraDevice],
      mirrorLocalVideo: [settings.mirrorLocalVideo],
      blurBackground: [settings.blurBackground],
      cameraOn: [settings.cameraOn],
    });
  }

  async scanDevices() {
    this.activeCameraList = [];
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      const mediaDevices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
      if (mediaDevices && mediaDevices.length > 0) {
        this.activeCameraList = mediaDevices.filter(
          (camera: MediaDeviceInfo) => camera.deviceId && camera.kind === 'videoinput'
        );
        if (this.activeCameraList.length > 0) {
          if (this.cameraDevice) {
            this.selectedCamera = this.activeCameraList.find(
              (camera: MediaDeviceInfo) => camera.deviceId === this.cameraDevice
            );
            if (!this.selectedCamera) {
              this.selectFirstCamera();
            }
          } else {
            this.selectFirstCamera();
          }
        }
      }
    }
  }

  selectFirstCamera() {
    this.selectedCamera = this.activeCameraList[0];
    this.cameraDevice = this.selectedCamera.deviceId;
  }

  async tryStartCameraStream() {
    try {
      await this.startCameraStream();
    } catch (error) {
      alert(error);
    }
  }

  async startCameraStream() {
    const logger = new ConsoleLogger('uc-web-v2 Video Preferences', LogLevel.INFO);
    this.deviceController = new DefaultDeviceController(logger);

    // Get the input stream. Use either the selected camera or query for one using `getUserMedia`
    let inputDevice: Device | undefined = this.selectedCamera;
    if (!inputDevice) {
      inputDevice = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: this.cameraDevice ?? undefined,
        },
      });
    }

    if (!inputDevice) {
      throw new Error(this.appTranslateService.instant('VIDEO_SETTINGS_PREFERENCES.NOT_SUPPORTED_YOUR_CAMERA'));
    }

    const transformDevice = await this.chimeMeetingService.getVideoTransformDevice(
      this.selectedCamera!,
      this.blurBackground
    );
    const mediaStream = await this.deviceController.startVideoInput(transformDevice);
    if (!mediaStream) {
      throw new Error(this.appTranslateService.instant('VIDEO_SETTINGS_PREFERENCES.NOT_SUPPORTED_YOUR_CAMERA'));
    }
    if (this.componentDestroyed) {
      await this.stopCameraStream(mediaStream);
      return;
    }
    this.bindStreamToVideoElement(mediaStream);
  }

  private bindStreamToVideoElement(stream: MediaStream) {
    this.currentMediaStream = stream;
    const videoElement = this.webcam.nativeElement;
    videoElement.srcObject = stream;
    videoElement.muted = videoElement.autoplay = videoElement.playsInline = true;
  }

  private async stopCameraStream(stream: MediaStream) {
    if (stream) {
      for (const track of stream.getTracks()) {
        track.stop();
      }
      await this.deviceController?.stopVideoInput();
    }
  }

  async onCameraChanged(selectedCamera: MediaDeviceInfo) {
    this.disabled = false;
    // stop previous camera's stream
    await this.stopCameraStream(this.currentMediaStream);
    // start new camera's stream
    this.cameraDevice = selectedCamera.deviceId;
    this.selectedCamera = selectedCamera;
    await this.tryStartCameraStream();
  }

  async save() {
    this.loading = true;
    await firstValueFrom(
      this.appConfigService.saveVideoSettings(this.form.value).pipe(finalize(() => (this.loading = false)))
    );
  }

  onFormChange() {
    this.disabled = false;
  }

  async onBackgroundStatusChange(event) {
    const isChecked = event.checked;
    this.disabled = false;
    this.blurBackground = isChecked;
    await this.stopCameraStream(this.currentMediaStream);
    await this.tryStartCameraStream();
  }

  async ngOnDestroy() {
    this.componentDestroyed = true;
    await this.stopCameraStream(this.currentMediaStream);
  }
}
