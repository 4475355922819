import { Injectable } from '@angular/core';
import Dexie, { liveQuery, Table } from 'dexie';
import { from, Observable } from 'rxjs';

import { LambdaLogDBModel } from '../models/lambda.model';

@Injectable({
  providedIn: 'root',
})
export class LambdaStorageService {
  private db = new LambdaLogDB();

  public async insert(log: LambdaLogDBModel): Promise<void> {
    await this.db.logs.add({
      ...log,
    });

    // If the log count > 10, delete the oldest entry
    const count = await this.db.logs.count();
    if (count > 10) {
      const oldestLog = await this.db.logs.orderBy('createdAt').first();
      if (oldestLog?.id) {
        await this.db.logs.delete(oldestLog.id);
      }
    }
  }

  public liveQuery(filter?: (LambdaLogDBModel) => boolean): Observable<LambdaLogDBModel[]> {
    const dexieObservable = liveQuery(() => {
      let collection = this.db.logs.orderBy('createdAt').reverse();

      if (filter) {
        collection = collection.filter((item) => filter(item));
      }

      return collection.toArray();
    });

    return from(dexieObservable);
  }

  public drop() {
    this.db.resetDatabase();
  }
}

class LambdaLogDB extends Dexie {
  private static readonly DB_NAME = 'lambdaLogs';
  private static readonly TABLE_NAME = 'logs';
  public logs!: Table<LambdaLogDBModel & { id?: number }, number>;

  constructor() {
    super(LambdaLogDB.DB_NAME);
    this.version(1).stores({
      [LambdaLogDB.TABLE_NAME]:
        '++id, callObj, integrationTitle, lambdaOutputLogs, errorMessage, lambdaResult, createdAt',
    });
  }

  public async resetDatabase() {
    await this.transaction('rw', LambdaLogDB.TABLE_NAME, () => {
      this.logs.clear();
    });
  }
}
