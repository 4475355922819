<app-banner
  [open]="open"
  [text]="'BANNERS.ONE_CLICK_DIALING_MESSAGE' | translate: { pwaAction: (pwaInstalled ? '' : ('BANNERS.INSTALL_AND' | translate)) }"
  [actions]="[
    {
      text: 'BANNERS.LEARN_MORE_ACTION' | translate,
      tooltip: 'BANNERS.LEARN_MORE_TOOLTIP_TEL_HANDLER' | translate
    },
    pwaInstalled
      ? {
        text: 'BANNERS.REGISTER_NOW_ACTION' | translate,
        tooltip: 'BANNERS.REGISTER_NOW_TOOLTIP' | translate
      }
      : {
        text: 'BANNERS.INSTALL_APP_ACTION' | translate,
        tooltip: 'BANNERS.INSTALL_APP_TOOLTIP' | translate
      }
  ]"
  (actionClick)="handleActionClick($event)"
  type="info"
  customIcon="notifications_outline"
  (closeClick)="dismiss()"
  [closeTooltip]="'BANNERS.DISMISS_TOOLTIP' | translate"
></app-banner>
