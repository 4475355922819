<section class="notification-container">
  <form [formGroup]="formGroup">
    <div
      formArrayName="formItems"
      *ngFor="let item of formItems.controls; let i = index">
      <div
        class="container"
        [formGroupName]="i">
        <h1 class="m-b-8">{{ notificationItems[i].title }}</h1>
        <div class="flex-col">
          <mat-checkbox
            formControlName="enableSound"
            (ngModelChange)="onCheckChange()"
            color="primary">
            {{ 'NOTIFICATION_PREFERENCES.ENABLE_SOUND' | translate:{itemName: getItemName(i)} }}
          </mat-checkbox>
          <mat-checkbox
            formControlName="enableNotification"
            (ngModelChange)="onCheckChange()"
            color="primary">
            {{ 'NOTIFICATION_PREFERENCES.SHOW_NOTIFICATION_MINIMIZED' | translate }}
          </mat-checkbox>
          <mat-checkbox
            formControlName="enableForegroundNotification"
            (ngModelChange)="onCheckChange()"
            class="enable-notification"
            color="primary">
            {{ 'NOTIFICATION_PREFERENCES.SHOW_NOTIFICATION_FOREGROUND' | translate }}
          </mat-checkbox>
          <mat-form-field
            appearance="outline"
            class="select-dropdown density-minus-3">
            <mat-label>{{ 'NOTIFICATION_PREFERENCES.SELECT_LABEL' | translate }}</mat-label>
            <div class="flex">
              <mat-select
                formControlName="soundSelect"
                (selectionChange)="onRingtoneChanged(i, $event.value)"
                class="device-list">
                <mat-option
                  *ngFor="let option of notificationItems[i].selectOptions"
                  [value]="option.ringtone">
                  {{ option.title }}
                </mat-option>
              </mat-select>
              <mat-icon
                (click)="play(item.value.soundSelect, $event)"
                class="speaker-icon"
                >volume_up</mat-icon
              >
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</section>
