<div class="video-settings-container">
  <div class="video-header">
    <h1 class="header-title">{{ 'VIDEO_SETTINGS_PREFERENCES.VIDEO_SETTINGS' | translate }}</h1>
    <button
      class="rescan-button"
      (click)="scanDevices()">
      {{ 'VIDEO_SETTINGS_PREFERENCES.RESCAN_DEVICES' | translate }}
    </button>
  </div>
  <div class="webcam-container">
    <video
      data-cy="webcam"
      class="webcam"
      #video
      id="video"
      width="530"
      autoplay="autoplay"
      [ngClass]="{ mirrorVideo: mirrorLocalVideo, normalVideo: !mirrorLocalVideo }"></video>
  </div>

  <mat-form-field
    data-cy="cameraList"
    class="full form-no-error select-list-container"
    color="primary"
    *ngIf="activeCameraList.length > 0"
    appearance="outline">
    <mat-label>{{ 'VIDEO_SETTINGS_PREFERENCES.CAMERA' | translate }}</mat-label>
    <mat-select
      [disabled]="activeCameraList.length === 1"
      class="select-list-label"
      [(value)]="selectedCamera"
      (selectionChange)="onCameraChanged($event.value)">
      <mat-option
        class="select-list-item"
        *ngFor="let item of activeCameraList"
        [value]="item">
        {{ item.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <form [formGroup]="form">
    <div class="flex-col">
      <mat-checkbox
        data-cy="mirrorLocalVideo"
        formControlName="mirrorLocalVideo"
        (change)="onFormChange()"
        color="primary"
        >{{'VIDEO_SETTINGS_PREFERENCES.MIRROR_LOCAL_VIDEO' | translate}}
      </mat-checkbox>
      <mat-checkbox
        data-cy="blurBackground"
        formControlName="blurBackground"
        name="blurBackgroundCheckbox"
        (change)="onBackgroundStatusChange($event)"
        color="primary"
        >{{ 'VIDEO_SETTINGS_PREFERENCES.BLUR_BACKGROUND_DURING_CALLS' | translate }}
      </mat-checkbox>
      <mat-checkbox
        data-cy="cameraOn"
        formControlName="cameraOn"
        (change)="onFormChange()"
        color="primary"
        >{{'VIDEO_SETTINGS_PREFERENCES.CAMERA_WHEN_ENTERING_MEETING' | translate}}
      </mat-checkbox>
    </div>
  </form>
</div>
